import belgium from "../image/country/belgium.png"
import bulgaria from "../image/country/bulgaria.png"
import germany from "../image/country/germany.png"
import netherlands from "../image/country/netherlands.png"
import switzerland from "../image/country/switzerland.png"
import denmark from "../image/country/denmark.png"
import estonia from "../image/country/estonia.png"
import finland from "../image/country/finland.png"
import france from "../image/country/france.png"
import greece from "../image/country/greece.png"
import britain from "../image/country/britain.png"
import irish from "../image/country/irish.png"
import iceland from "../image/country/iceland.png"
import italy from "../image/country/Italy.png"
import croatia from "../image/country/croatia.png"
import latvia from "../image/country/latvia.png"
import liechtenstein from "../image/country/liechtenstein.png"
import lithuania from "../image/country/lithuania.png"
import luxembourg from "../image/country/luxembourg.png"
import moldova from "../image/country/moldova.png"
import monaco from "../image/country/monaco.png"
import montenegro from "../image/country/montenegro.png"
import norway from "../image/country/norway.png"
import poland from "../image/country/poland.png"
import portugal from "../image/country/portugal.png"
import romania from "../image/country/romania.png"
import sweden from "../image/country/sweden.png"
import serbia from "../image/country/serbia.png"
import spain from "../image/country/spain.png"
import czech from "../image/country/czech.png"
import ukraine from "../image/country/ukraine.png"
import hungary from "../image/country/hungary.png"
import austria from "../image/country/austria.png"

export const country = [
    {
        "image": germany,
        "value": "germany",
        "name": "Deutschland"
    },
    {
        "image": belgium,
        "value": "belgium",
        "name": "Belgien"
    },
    {
        "image": bulgaria,
        "value": "bulgaria",
        "name": "Bulgarien"
    },
    {
        "image": netherlands,
        "value": "netherlands",
        "name": "Die Niederlande"
    },
    {
        "image": switzerland,
        "value": "switzerland",
        "name": "Die Schweiz"
    },
    {
        "image": denmark,
        "value": "denmark",
        "name": "Dänemark"
    },
    {
        "image": estonia,
        "value": "estonia",
        "name": "Estland"
    },
    {
        "image": finland,
        "value": "finland",
        "name": "Finnland"
    },
    {
        "image": france,
        "value": "france",
        "name": "Frankreich"
    },
    {
        "image": greece,
        "value": "greece",
        "name": "Griechenland"
    },
    {
        "image": britain,
        "value": "britain",
        "name": "Großbritannien"
    },
    {
        "image": irish,
        "value": "irish",
        "name": "Irland"
    },
    {
        "image": iceland,
        "value": "iceland",
        "name": "Island"
    },
    {
        "image": italy,
        "value": "italy",
        "name": "Italien"
    },
    {
        "image": croatia,
        "value": "croatia",
        "name": "Kroatien"
    },
    {
        "image": latvia,
        "value": "latvia",
        "name": "Lettland"
    },
    {
        "image": liechtenstein,
        "value": "liechtenstein",
        "name": "Liechtenstein"
    },
    {
        "image": lithuania,
        "value": "lithuania",
        "name": "Litauen"
    },
    {
        "image": luxembourg,
        "value": "luxembourg",
        "name": "Luxemburg"
    },
    {
        "image": moldova,
        "value": "moldova",
        "name": "Moldawien"
    },
    {
        "image": monaco,
        "value": "monaco",
        "name": "Monaco"
    },
    {
        "image": montenegro,
        "value": "montenegro",
        "name": "Montenegro"
    },
    {
        "image": norway,
        "value": "norway",
        "name": "Norwegen"
    },
    {
        "image": poland,
        "value": "poland",
        "name": "Polen"
    },
    {
        "image": portugal,
        "value": "portugal",
        "name": "Portugal"
    },
    {
        "image": romania,
        "value": "romania",
        "name": "Rumänien"
    },
    {
        "image": sweden,
        "value": "sweden",
        "name": "Schweden"
    },
    {
        "image": serbia,
        "value": "serbia",
        "name": "Serbien"
    },
    {
        "image": spain,
        "value": "spain",
        "name": "Spanien"
    },
    {
        "image": czech,
        "value": "czech",
        "name": "Tschechien"
    },
    {
        "image": ukraine,
        "value": "ukraine",
        "name": "Ukraine"
    },
    {
        "image": hungary,
        "value": "hungary",
        "name": "Ungarn"
    },
    {
        "image": austria,
        "value": "austria",
        "name": "Österreich"
    }
]