import { useContext, useEffect, useRef, useState } from "react"
import { ProductSlider } from "../../component/ProductSlider"
import { language } from "./language"
import {BreadItemType, Product} from "../../classes/"
import { Loader } from "../../component/Loader"
import { Link, useParams } from "react-router-dom"

import min from '../../image/min.svg'
import plass from '../../image/plass.svg'
import box from '../../image/box.svg'
import Slider from "react-slick"
import { Pagination } from "../../component/Pagination"
import { GlobalContext } from "../../context/GlobalContext"


export const ProductItem = () => {
    const auth = useContext(GlobalContext)    
    
    const [load, setLoad] = useState(true)
    const [breadcrumbs, setBreadcrumbs] = useState<BreadItemType[]>()
    const [targetSize, setTargetSize] = useState(0)
    const [targetMaxCount, setTargetMaxCount] = useState(0)
    const [itemCount, setItemCount] = useState(1)

    const [inFavourites, setInFavourites] = useState(true)

    const [showToCart, setShowToCart] = useState(false)
    const [loadReview, setLoadReview] = useState(true)
    const [product, setProduct] = useState(new Product())    
    const { slug } = useParams();
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    useEffect(()=>{ 
        if(slug){
            const timer = setInterval(() => {
                if(auth.category.treeList.length >= 1 && product.product?.category?.slug){
                    clearInterval(timer)
                    auth.category.GetBreadcrumbs(product.product?.category?.slug).then(function(response){
                        setBreadcrumbs(response)
                    }).catch(function(error){
                        //alert("error")
                        console.log("error")
                    })
                }
            }, 1000);
        }
    },[])

    useEffect(()=>{
        if(slug)
            product.LoadData(slug, auth.user.country.value).then(function(response){


 


                if(product.product && product.product.size_list?.length){
                    for(let i=0; i < product.product.size_list.length; i++){
                        if(product.product.size_list[i].quantity > 0){
                            setTargetMaxCount(product.product.size_list[i].quantity)
                            setTargetSize(i)
                            break
                        }
                    }
                }

                if(auth.user.AddToPreviously(product.product!.pk))
                    auth.user.GetPreviously().then(function(response){
                        console.log(auth.user.previously)
                    }).catch(function(error){
                        console.log("error")
                    })

                console.log(auth.user.previouslyID)
                setLoad(false)
                product.LoadReview().then(function(response){
                    setLoadReview(false) 
                }).catch(function(error){
                    alert("error")
                })
            }).catch(function(error){
                alert("error")
            })
    }, [])
 

    const SwitchPage = (page: number) => {
        setLoadReview(true)
        product.LoadReview(page).then(function(response: any){
            setLoadReview(false)
        }).catch(function(error: any){
            alert("error")
        })
    }


    const UpdateFavourites = () => {
        setInFavourites(!inFavourites) 
        auth.user.SetFavourites(product.product!.pk).then(function(response){
            console.log("test")
        }).catch(function(error){
            console.log("error")
        })
    }

    if(load || !product.product){
        return(
            <div className="catalog-loader">
                <Loader />
            </div>
        )
    }

    return (
        <>
            <div className="breadcrumb">
                <div className="content">
                    <div className="breadcrumb-list">
                        <div className="breadcrumb-list__item">
                            <a href="/">
                                {language.Deutsch.home}
                            </a>
                        </div>
                        
                        {
                            breadcrumbs ? (
                                breadcrumbs.length ? (
                                    breadcrumbs.map((item, index) => {
                                        if(index == breadcrumbs.length-1)
                                            return(
                                                <div className="breadcrumb-list__item active">
                                                    {item.content_category[0].name}
                                                </div>
                                            )
                                        
                                        return(
                                            <div className="breadcrumb-list__item">
                                                <Link to={`/katalog/${item.slug}/`}>
                                                    {item.content_category[0].name}
                                                </Link>
                                            </div>
                                        )
                                    })
                                ):""
                            ):""
                        }
                    </div>
                </div>
            </div>

            <section className="detail-product"> 
                <div className="content big-padding__btn">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="detail-product__text-info"> 
                                <div className="detail-product__slider">
                                    <Slider {...settings}>
                                        {
                                            product.product.image_list ? (
                                                product.product.image_list.map((item, index) => {
                                                    return(
                                                        <div key={index} className="detail-product__main"> 
                                                            <img src={`https://api.vivigon.com${item.image}`} />
                                                        </div>
                                                    )
                                                })
                                            ):""
                                        }
                                    </Slider>
                                </div>
                                <div className="detail-product__desc hide-mob">
                                    <h3 className="title margin-title">{language.Deutsch.desc}</h3>
                                    {product.product.content_product.content}
                                </div>
                            </div>            
                        </div>
                        <div className="col-md-5">
                            <div className="detail-product__info">
                                <h2>
                                    <Link to={`/lieferer/${product.product.seller!.slug}/`}>{product.product.seller!.content_seller.title}</Link> | <Link to={`/luxusmarken/${product.product.brand!.slug}/`}>{product.product.brand!.content_brand.name}</Link>
                                </h2>
                                <h1>{product.product.content_product.name}</h1>
                                <div>
                                    <svg className={`star ${product.product.rating >= 1 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                    <svg className={`star ${product.product.rating >= 2 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                    <svg className={`star ${product.product.rating >= 3 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                    <svg className={`star ${product.product.rating >= 4 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                    <svg className={`star ${product.product.rating >= 5 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                </div>
                                <div className="products-item__price">
                                    €{product.product.finalPrice}
                                    {
                                        product.product.discount > 0 ? (
                                            <span>{product.product.price}</span>   
                                        ):""
                                    }     
                                </div>
                            </div>

                            {
                                product.product.size_list ? (
                                    product.product.size_list.length ? (
                                        <div className="detail-product__size-list">
                                            {
                                                product.product.size_list.map((item, index) => {
                                                    return (
                                                        <div className={`${product.product!.size_list![index].quantity == 0 ? "zero-item":""} ${targetSize == index ? "active":""}`} onClick={()=>{
                                                            if(product.product!.size_list![index].quantity > 0){
                                                                setTargetSize(index)
                                                                setTargetMaxCount(product.product!.size_list![index].quantity)
                                                            }
                                                        }}>
                                                            <span>{item.name}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ):""
                                ):""
                            }

                            <div className="row detail-product__btn-line"> 
                                {
                                    targetMaxCount > 0 ? (
                                        <div className="detail-product__to-cart">
                                            <div className="detail-product__counter">
                                                <button className="min-count" id="product-count__detail" onClick={()=>{
                                                    if(itemCount-1 > 0)
                                                        setItemCount(itemCount-1)
                                                }}>
                                                    <div className="grid center">
                                                        <img src={min} />
                                                    </div>
                                                </button>
        
                                                <input type="number" id="add-to-cart" value={itemCount} min="1" max={targetMaxCount} />
                                                <button className="plus-count" onClick={()=>{
                                                    if(itemCount+1 <= targetMaxCount)
                                                        setItemCount(itemCount+1)
                                                }}>
                                                    <div className="grid center">
                                                        <img src={plass} />
                                                    </div>
                                                </button>
                                            </div>
                                        </div> 
                                    ):""
                                }
 

                                <button 
                                    id="detail-product__add-to-cart" 
                                    className={`btn-black center detail-product__add-to-cart ${targetMaxCount == 0 ? "unactive":""}`}
                                    onClick={()=>{
                                        if(targetMaxCount > 0 && product.product){
                                            console.log(itemCount)
                                            auth.user.cart.AddToCart(
                                                auth.user.userToken, auth.user.userUid,
                                                {
                                                    pk: product.product.pk,
                                                    count: itemCount,
                                                    size: product.product!.size_list![targetSize].pk
                                                }
                                            ).then(function(response){
                                                setShowToCart(true)
                                                setTimeout(function(){
                                                    setShowToCart(false)
                                                }, 2000);
                                            }).catch(function(error){
                                                alert("error")
                                            })
                                        }
                                    }}
                                >
                                    {
                                        targetMaxCount == 0 ? language.Deutsch.unactive : language.Deutsch.addToCart
                                    }
                                </button>
                                <div className={`detail-product__like product-item__like-btn ${inFavourites ? "active":""}`} onClick={UpdateFavourites}></div>
                            </div>

                            {
                                targetMaxCount > 0 ? (
                                    <div className="detail-product__max-count">{language.Deutsch.maximum}: {targetMaxCount}</div>
                                ):""
                            }

                            <p className="image-line">
                                <img src={box} />
                                {language.Deutsch.payAndDeliv}
                            </p>

                            <div className="share-line">
                                <p>{language.Deutsch.share}:</p>                     
                                <Link to={`https://twitter.com/intent/tweet?text=${product.product.content_product.name}&url=https://vivigon.com/produkt/${slug}/&utm_source=share2`} target="_blank">
                                    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.0492 4.84589C18.0492 5.0247 18.0492 5.20352 18.0492 5.35679C18.0492 10.6446 14.013 16.7498 6.6561 16.7498C4.38259 16.7498 2.2879 16.0857 0.499756 14.9617C0.806296 14.9872 1.13838 15.0128 1.44492 15.0128C3.3097 15.0128 5.04676 14.3742 6.42619 13.3013C4.66359 13.2757 3.18198 12.1007 2.69662 10.5169C2.95207 10.568 3.18198 10.5935 3.46297 10.5935C3.8206 10.5935 4.17823 10.5424 4.51032 10.4402C2.67108 10.0826 1.29165 8.47327 1.29165 6.53185C1.29165 6.50631 1.29165 6.50631 1.29165 6.48076C1.82809 6.7873 2.44117 6.96612 3.10534 6.99166C2.03245 6.2764 1.3172 5.05025 1.3172 3.64527C1.3172 2.90447 1.52155 2.21475 1.85364 1.62722C3.8206 4.05399 6.78382 5.63778 10.1047 5.8166C10.028 5.5356 10.0025 5.20352 10.0025 4.89698C10.0025 2.67456 11.7906 0.886414 14.013 0.886414C15.1626 0.886414 16.2099 1.37177 16.9252 2.13812C17.8448 1.9593 18.6878 1.62722 19.4797 1.16741C19.1731 2.11257 18.5345 2.87892 17.7171 3.38982C18.5345 3.28764 19.3009 3.08328 20.0161 2.7512C19.5052 3.56864 18.841 4.2839 18.0492 4.84589Z" fill="black"/>
                                    </svg>
                                </Link> 

                                <Link to={`https://t.me/share/url?url=https://vivigon.com/produkt/${slug}/&text=${product.product.content_product.name}&utm_source=share2`} target="_blank">
                                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.99256 7.02405C6.66626 4.94499 9.78279 3.57435 11.3422 2.91213C15.7944 1.02134 16.7196 0.69289 17.3226 0.681929C17.4552 0.679659 17.7518 0.713218 17.9438 0.872357C18.106 1.00673 18.1507 1.18825 18.172 1.31565C18.1934 1.44305 18.22 1.73328 18.1988 1.96005C17.9576 4.54839 16.9136 10.8296 16.3825 13.7286C16.1577 14.9553 15.7152 15.3666 15.2868 15.4068C14.3558 15.4943 13.6488 14.7786 12.7471 14.1751C11.336 13.2307 10.5389 12.6428 9.16925 11.7213C7.58637 10.6562 8.61248 10.0709 9.51456 9.11427C9.75064 8.86391 13.8527 5.05433 13.9321 4.70874C13.942 4.66552 13.9513 4.50441 13.8575 4.41934C13.7638 4.33427 13.6254 4.36336 13.5256 4.3865C13.3841 4.41929 11.1299 5.94052 6.76312 8.95017C6.12329 9.39876 5.54375 9.61733 5.0245 9.60588C4.45207 9.59325 3.35095 9.27542 2.53237 9.00374C1.52836 8.67051 0.730387 8.49433 0.799872 7.92842C0.836064 7.63365 1.23363 7.33219 1.99256 7.02405Z" fill="black"/>
                                    </svg>
                                </Link>

                                <Link to={`https://api.whatsapp.com/send?text=${product.product.content_product.name} https://vivigon.com/produkt/${slug}/&utm_source=share2`} target="_blank">
                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8925 2.65577C15.4321 2.23248 13.5682 0.877237 9.41331 0.858672C9.41331 0.858672 4.51586 0.561632 2.1284 2.7523C0.799143 4.08156 0.331304 6.02346 0.283035 8.43691C0.234766 10.8467 0.171645 15.3654 4.527 16.5907H4.53071L4.527 18.4583C4.527 18.4583 4.50101 19.2158 4.99855 19.368C5.60006 19.5536 5.9565 18.9781 6.53202 18.3581C6.84762 18.0165 7.28205 17.5189 7.6125 17.1365C10.5903 17.3853 12.8813 16.8134 13.1412 16.7281C13.7427 16.5313 17.1475 16.0968 17.7007 11.5781C18.2725 6.92943 17.4223 3.98502 15.8925 2.65577ZM16.3975 11.2514C15.9296 15.0238 13.1709 15.2614 12.6622 15.4248C12.4468 15.4953 10.4344 15.9966 7.90212 15.8295C7.90212 15.8295 6.01591 18.1056 5.42926 18.6959C5.33643 18.7888 5.22875 18.8259 5.15821 18.8073C5.05796 18.7813 5.02825 18.6625 5.03197 18.488C5.03197 18.2355 5.04682 15.3802 5.04682 15.3802C5.0431 15.3802 5.0431 15.3802 5.04682 15.3802C1.35981 14.3592 1.57516 10.5125 1.616 8.50003C1.65685 6.48759 2.03557 4.8353 3.16061 3.72511C5.17677 1.89831 9.33905 2.16936 9.33905 2.16936C12.8515 2.18421 14.5335 3.24242 14.9234 3.59516C16.2192 4.70906 16.8802 7.36386 16.3975 11.2514Z" fill="black"/>
                                        <path d="M11.1237 8.57051C11.0011 8.57051 10.8972 8.47397 10.8897 8.34773C10.8489 7.54944 10.4739 7.15957 9.70901 7.11873C9.57905 7.1113 9.4788 6.99991 9.48623 6.86996C9.49365 6.74 9.60504 6.63975 9.735 6.64718C10.7449 6.70287 11.3093 7.2821 11.3613 8.32174C11.3687 8.4517 11.2685 8.56309 11.1385 8.5668C11.1311 8.57051 11.1274 8.57051 11.1237 8.57051Z" fill="black"/>
                                        <path d="M12.3281 8.97168H12.3244C12.1945 8.96797 12.0905 8.86029 12.0942 8.73033C12.1128 7.92833 11.8826 7.27855 11.3925 6.74388C10.9061 6.2092 10.234 5.91959 9.3466 5.85647C9.21665 5.84533 9.12011 5.73394 9.13125 5.60399C9.14238 5.47403 9.25378 5.37749 9.38373 5.38863C10.3862 5.46289 11.1771 5.81191 11.7415 6.42827C12.3096 7.04834 12.5843 7.82436 12.5658 8.74147C12.5621 8.87143 12.4544 8.97168 12.3281 8.97168Z" fill="black"/>
                                        <path d="M13.5603 9.45418C13.4304 9.45418 13.3264 9.35021 13.3264 9.22026C13.3153 7.78332 12.9031 6.68799 12.064 5.86742C11.236 5.05798 10.1852 4.64584 8.94876 4.6347C8.81881 4.6347 8.71484 4.52702 8.71484 4.39707C8.71484 4.26711 8.82252 4.16315 8.94876 4.16315C10.3114 4.17429 11.4699 4.63099 12.387 5.52953C13.3078 6.42808 13.7794 7.66822 13.7942 9.21283C13.7979 9.3465 13.694 9.45418 13.5603 9.45418C13.564 9.45418 13.564 9.45418 13.5603 9.45418Z" fill="black"/>
                                        <path d="M9.76475 11.4778C9.76475 11.4778 10.0952 11.5075 10.2734 11.2884L10.6187 10.854C10.7858 10.6386 11.1905 10.5013 11.5841 10.7203C11.8032 10.8429 12.2005 11.0879 12.4455 11.2699C12.7092 11.4629 13.2438 11.9085 13.2475 11.9122C13.5037 12.1276 13.5632 12.4432 13.3886 12.781C13.3886 12.781 13.3886 12.7848 13.3886 12.7885C13.2104 13.1041 12.9691 13.4011 12.6683 13.6759C12.6646 13.6759 12.6646 13.6796 12.6609 13.6796C12.4121 13.8875 12.1671 14.0063 11.9294 14.0323C11.8997 14.0398 11.8663 14.0398 11.8218 14.0398C11.7178 14.0398 11.6138 14.0249 11.5099 13.9915L11.5024 13.9803C11.1311 13.8764 10.5148 13.6165 9.48998 13.0484C8.82164 12.6808 8.26841 12.3058 7.79686 11.9308C7.54808 11.734 7.2956 11.5149 7.03569 11.255C7.02826 11.2476 7.01712 11.2364 7.0097 11.229C7.00227 11.2216 6.99113 11.2104 6.98371 11.203C6.97628 11.1956 6.96514 11.1845 6.95772 11.177C6.95029 11.1696 6.93915 11.1585 6.93173 11.151C6.67553 10.8911 6.45275 10.6386 6.25596 10.3899C5.88095 9.92203 5.50593 9.36508 5.13834 8.69674C4.57025 7.66824 4.31035 7.05188 4.20638 6.68429L4.19524 6.67687C4.16182 6.5729 4.14697 6.46894 4.14697 6.36497C4.14697 6.32042 4.14697 6.287 4.1544 6.2573C4.1841 6.01595 4.30292 5.77461 4.50713 5.52583C4.50713 5.52212 4.51085 5.52212 4.51085 5.51841C4.78561 5.21394 5.08265 4.97631 5.39825 4.79809C5.39825 4.79809 5.40197 4.79809 5.40568 4.79809C5.73985 4.62358 6.05546 4.68298 6.27452 4.93918C6.27452 4.93918 6.7238 5.47757 6.91687 5.74119C7.09881 5.98996 7.34387 6.38354 7.4664 6.60261C7.68547 6.99618 7.54808 7.4009 7.33273 7.56799L6.89831 7.9133C6.67924 8.09152 6.70895 8.42198 6.70895 8.42198C6.70895 8.42198 7.35129 10.8651 9.76475 11.4778Z" fill="black"/>
                                    </svg>
                                </Link>     
                            </div> 

                            {
                                product.offers.length ? (
                                    <div className="detail-product__offers-list">
                                        <h3>{language.Deutsch.moreProduct}</h3>
                                
                                        {
                                            product.offers.map((item, index) => {
                                                return(
                                                    <Link to={`/produkt/${item.slug}/`}>
                                                        <div className="detail-product__offers-item">
                                                            <h2>
                                                                <Link to={`/lieferer/${item.seller.slug}/`}>{item.seller.content_seller.title}</Link> | <Link to={`/luxusmarken/${item.brand.slug}/`}>{item.brand.content_brand.name}</Link>
                                                            </h2>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="products-item__price">
                                                                        €{item.finalPrice}
                                                                        {
                                                                            item.discount > 0 ? (
                                                                                <span>{item.price}</span>   
                                                                            ):""
                                                                        }            
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="star-list">
                                                                        <svg className={`star ${item.rating >= 1 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                        <svg className={`star ${item.rating >= 2 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                        <svg className={`star ${item.rating >= 3 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                        <svg className={`star ${item.rating >= 4 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                        <svg className={`star ${item.rating >= 5 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                    </Link>
                                                )
                                            })
                                        }
                                    
                                        <a href={`/handelsangebote/${product.product.manufacturerBarcode}/`} className="btn-black center">
                                            {language.Deutsch.showMore}
                                        </a>
                                    </div>   
                                ):""
                            }
                        
                            <div className="detail-product__desc show-mob">
                                <h3 className="title margin-title">{language.Deutsch.descTitle}</h3>
                                miniDesc
                           </div>          
                        </div>
                    </div>
                </div>
            </section>

            {
                product.product.rating > 0 ? (
                    <section className="product-reviews">
                        <div className="content big-padding__btn">
                            <div className="row">
                                <div className="col-md-11">
                                    <h3 className="title margin-title">{language.Deutsch.reviewsTitle} <span className="title__count"></span></h3>
                                </div>
                                <div className="col-md-1">
                                    <div className="product-reviews__rate"> 
                                        <div className="product-reviews__number grid">
                                            <div className="">
                                                <h3>{product.product.rating}</h3>
                                                <p>of 5</p>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div> 
                            { 
                                !loadReview && product.review.length ? (
                                    <>
                                        <div className="product-reviews__list">
                                            { 
                                                product.review ? (
                                                    product.review?.map(item => {
                                                        return (
                                                            <div className="product-reviews__item">
                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        <div className="product-reviews__item-star">
                                                                            <svg className={`star ${item.rating >= 1 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                            <svg className={`star ${item.rating >= 2 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                            <svg className={`star ${item.rating >= 3 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                            <svg className={`star ${item.rating >= 4 ? "active":""}`} height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                            <svg className={`star ${item.rating >= 5 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="col-md-10 product-reviews__item-desc">
                                                                        {item.text}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                ):""
                                            }
                                        </div>
                                        <Pagination 
                                            product={product} 
                                            page={product.page}
                                            pageCount={product.pageCount}
                                            setLoadReview={setLoadReview} 
                                            SwitchPage={SwitchPage} 
                                            
                                        />
                                    </>
                                ):""
                            }
                        </div>
                    </section>
                ):""
            }
            

            {
                auth.user.previously ? (
                    auth.user.previously.length ? (
                        <section className="products-slider">
                            <div className="content big-padding__btn">
                                <h3 className="title center margin-title">{language.Deutsch.productStoryTitle}</h3> 
                                <ProductSlider products={auth.user.previously} />
                            </div>
                        </section>
                    ):""
                ):""
            }


            <div className={`${showToCart ? "active":""} product-window`} id="product-window">
                <div className="row">
                    <div className="col-md-4 grid" >
                        <img src={`http://api.vivigon.com/${product.product.image_list![0].image}`} id="header-count_image" height="85" width="" />
                    </div>
                    <div className="col-md-8" id="header-count_text">PRESS GURWITZ PERFUMERIE № 9</div>
                </div>
            </div>

        </>
    )
}