import instagram_icon1 from "../../image/instagram_icon1.png"
import instagram_icon2 from "../../image/instagram_icon2.png"
import instagram_icon3 from "../../image/instagram_icon3.png"
import instagram_icon4 from "../../image/instagram_icon4.png"
import instagram_icon5 from "../../image/instagram_icon5.png"
import bigInstagram from "../../image/big-instagram.svg"
import {language} from "./language"

export const Instagram = () => {
    return (
        <section className="instagram">
            <div className="content big-padding">
                <div className="center">
                    <img src={bigInstagram} />
                    <h3 className="title">{language.Deutsch.title}</h3>
                    <p className="instagram__link">
                        <a href="" target="_blank">@makeup.store</a>
                    </p>
                    <div className="row instagram__items">
                        <div className="col-sm-five">
                            <a href="" target="_blank">
                                <img src={instagram_icon1} />
                            </a>
                        </div>
                        <div className="col-sm-five">
                            <a href="" target="_blank">
                                <img src={instagram_icon2} />
                            </a>
                        </div>
                        <div className="col-sm-five">
                            <a href="" target="_blank">
                                <img src={instagram_icon3} />
                            </a>
                        </div>
                        <div className="col-sm-five">
                            <a href="" target="_blank">
                                <img src={instagram_icon4} />
                            </a>
                        </div>
                        <div className="col-sm-five">
                            <a href="" target="_blank">
                                <img src={instagram_icon5} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}