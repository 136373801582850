import {language} from "./language"
import Slider from "react-slick"

export const TopBrand = () => {
    const brands = [1,2,3,4,1,2,3,4,1,2,3,4]
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5
    }

    return (
        <section className="top-brand">
            <div className="content">
                <h3 className="title center margin-title big-padding">{language.Deutsch.title}</h3> 
                <Slider {...settings}>
                    {
                        brands.map((item, index) => {
                            return(
                                <div className="grid center" key={index}>
                                    <a href="luxusmarken/{{brand.slug}}/">
                                        <img src="{{brand.logo.image.url}}" />
                                    </a>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </section>
    )
}