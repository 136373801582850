import Slider from "react-slick"
import { Product } from "../Product"

export const ProductSlider = (props: any) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 5
    }

    return(
        <Slider {...settings}>
            {
                props.products.map((item:any, index:number) => {
                    return <Product item={item} />
                })
            }
        </Slider>
    )
}