import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { Layout } from "./layouts/layout" 
import { useMyRoutes } from "./routes"
import { Header } from './component/Header';
import { Footer } from './component/Footer';
import { Cookie } from './component/Cookie';
import { GlobalContext } from './context/GlobalContext';
import { Category, User } from './classes'; 
import { country } from './classes/country';
import { CountrySelector } from './component/CountrySelector';


function App() {
    const auth = useContext(GlobalContext)   
    const routes = useMyRoutes() 
    const [cookie, setCookie] = useState(auth.user.cookie)
    const [countryPopup, setCountryPopup] = useState(false)


    useEffect(()=>{
        if(!auth.user.countrySelected){ 
            setCountryPopup(true)
        }
    }, [])

  return (
    <GlobalContext.Provider value={{
        user: new User,
        category: new Category
    }}> 
        <Header setCountryPopup={setCountryPopup} />
        <Routes>
            <Route element={<Layout />}>{routes}</Route>
        </Routes> 
        <Footer />

        {
            cookie ? (
                <Cookie setCookie={setCookie} />
            ):""
        }

        {
            countryPopup ?(
                <CountrySelector setCountryPopup={setCountryPopup} />
            ):""
        }
        
    </GlobalContext.Provider>
  );
}

export default App;
