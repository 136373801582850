import { useContext, useEffect } from "react"
import { language } from "./language"
import { GlobalContext } from "../../context/GlobalContext"

export const Cookie = (props: any) => {
    const auth = useContext(GlobalContext)   

    return(
        <div className="cookie" id="cookie">
            <div>
                {language.Deutsch.content}
                <a href="/datenschutzerklarung/">{language.Deutsch.contentLink}</a>
                <button onClick={()=>{
                    auth.user.ConfCookie() 
                    props.setCookie(false)
                }} id="cookie__hide">{language.Deutsch.contentLink}</button>
            </div>
        </div>
    )
}