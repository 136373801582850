import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import country from "../../image/country/germany.jpg"
import menu from "../../image/menu.svg"
import profileCircle from "../../image/profile-circle.svg"
import heart from "../../image/heart.svg"
import bag from "../../image/bag-2.svg"
import {language} from "./language"
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { CategoryItem } from "./categoryItem";
import { CategoryItemMob } from "./categoryItemMob";
import { RightCart } from "../RightCart";

export const Header = (props: any) => {   
    let location = useLocation();
    const navigate = useNavigate();
    const auth = useContext(GlobalContext)  
    
    const langList = [
        "Deutsch",
        "English"
    ]

    const [searchParams, setSearchParams] = useSearchParams();
    const [menuList, setMenuList] = useState([{}])
    const [openMenu, setOpenMenu] = useState(false)
    const [openCart, setOpenCart] = useState(false)

    const [openLanguage, setOpenLanguage] = useState(false)

    const [searchInput, setSearchInput] = useState(searchParams.get('search') ? `${searchParams.get('search')}` : "")//

    const [rightCat, setRightCat] = useState(0)

    useEffect(()=>{
        auth.category.GetCategory().then((result: {
            category: {}[],
            favourites: number[]
        }) => {
            setMenuList(result.category)
        }).catch((error) => {
            alert("error")
        })

        auth.user.GetPreviously().then(function(response){
            //console.log(auth.user.previously)
        }).catch(function(error){
            console.log("error")
        })

        auth.user.GetFavourites().then(function(response){
            //console.log(auth.user.previously)
        }).catch(function(error){
            console.log("error")
        })

        auth.user.cart.LoadCart(auth.user.userToken, auth.user.userUid).then(function(response){
        }).catch(function(error){
        })

    },[])
 
    const GetNode = (array: {}[], deep = 0) => {
        return (
            array ? (
                array.length ? (
                    array.map((item: any, index) => {
                        if(deep == 0)
                            return (
                                <div className={`catalog-menu__list ${index == rightCat ? "active":""}`} id="" >
                                    <div className="row">
                                        {GetNode(item.children, 1)}
                                    </div>
                                </div>
                            )
                        return (
                            <CategoryItem deep={deep} item={item} GetNode={GetNode} />
                        )
    
                    })
                ):""
            ):""
        )
    }

    const GetMobNode = (array: {}[], deep = 0) => {
        return (
            array ? (
                array.length ? (
                    array.map((item: any, index) => {
                        return (
                            <CategoryItemMob deep={deep} item={item} GetNode={GetMobNode} />
                        )
                    })
                ):""
            ):""
        )
    }
//action={location.pathname.indexOf("katalog") < 0 ? "/katalog/" : ""}
    return (
        <> 
            <header>
                <div className="header__top-line">
                    <div className="content">
                        <div className="row">
                            <div className="col-md-4 grid order-md-2">
                                <div className="header__contact-block">
                                    <div className="header__contact-item country" onClick={()=>{
                                        props.setCountryPopup(true)
                                    }}>
                                        <img src={auth.user.country.image} />
                                        <b>{language.Deutsch.country}:</b><br/>
                                        <span>{auth.user.country.name}</span>
                                    </div>    
                                    <div className="header__contact-item language">
                                        <b onClick={()=>{setOpenLanguage(!openLanguage)}}>{language.Deutsch.language}:</b><br/>
                                        <span onClick={()=>{setOpenLanguage(!openLanguage)}}>{auth.user.language}</span> 
                                        {
                                            openLanguage ? (
                                                <div className="language-list">
                                                    <ul>
                                                        {
                                                            langList.map((item, index)=>{
                                                                return(
                                                                    <li onClick={()=>{
                                                                        auth.user.language = item
                                                                        auth.user.ConfCookie()
                                                                        setOpenLanguage(false)
                                                                    }}>{item}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            ):""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 center order-md-1">
                                <Link to="/"> 
                                    <svg version="1.1" id="Слой_1" style={{width: "180px"}} x="0px" y="0px" viewBox="0 0 2477 557.7">
                                        <path d="M0,0h55.3l116,232.1L286.9,0h55.3L171.3,339.7L0,0z"/>
                                        <path d="M503.4,0.8v328.4h-50.3V0.8H503.4z"/>
                                        <path d="M614.3,0h55.3l116,232.1L901.2,0h55.3L785.6,339.7L614.3,0z"/>
                                        <path d="M2200.1,329.2h-50.3V-7.5l276.9,237.9V0.8h50.3v338.9l-276.9-237.9V329.2z"/>
                                        <path d="M1858.2,329.2c-49.7,0-92.3-15.8-127.8-47.3c-35.5-31.8-53.2-70.8-53.2-116.9c0-30.4,8.2-58.4,24.7-83.8 c16.8-25.4,38.9-45.1,66.6-59.1c27.7-14.2,57.5-21.4,89.6-21.4c49.7,0,92.3,15.9,127.8,47.8c35.5,31.8,53.2,70.7,53.2,116.4 c0,46.1-17.9,85-53.6,116.9C1949.8,313.5,1907.4,329.2,1858.2,329.2z M1765.7,245.5c25.7,22.3,56.5,33.5,92.6,33.5 s66.7-11.2,92.1-33.5c25.7-22.3,38.5-49.1,38.5-80.4c0-31.3-12.8-58.1-38.5-80.4c-25.4-22.3-56.1-33.5-92.1-33.5 s-66.9,11.2-92.6,33.5c-25.4,22.3-38.1,49.1-38.1,80.4C1727.6,196.3,1740.3,223.1,1765.7,245.5z"/>
                                        <path d="M1228.4,165c0-30.4,8.2-58.4,24.7-83.8c16.8-25.4,39-45.1,66.6-59.1c27.6-14.2,57.5-21.4,89.6-21.4 c30.7,0,59.9,6.8,87.6,20.5c27.6,13.7,49.6,32.5,65.8,56.5l-41.5,28.5c-12-17.3-28.1-30.9-48.2-40.6c-19.8-9.8-41.1-14.7-63.7-14.7 c-36,0-66.9,11.2-92.6,33.5c-25.4,22.3-38.1,49.1-38.1,80.4c0,31.3,12.7,58.1,38.1,80.4c25.7,22.3,56.6,33.5,92.6,33.5 c21,0,41.1-4.3,60.3-13c19.3-8.7,35-20.8,47.3-36.4v-26h-123.6v-50.3h173.8v92.6c-38.5,55.6-91.2,83.4-157.9,83.4 c-49.7,0-92.3-15.9-127.8-47.8C1246.1,249.6,1228.4,210.8,1228.4,165z"/>
                                        <path d="M1117.7,0.8v328.4h-50.3V0.8H1117.7z"/> 
                                        <path d="M297,553.9l17.5-89.5l28.6,64.7l29.6-64.7l15.7,89.5h-12.9l-8-50.3l-24.6,54.1l-23.9-54.1l-9,50.3H297z"/>
                                        <path d="M542.2,533.7h-35.6l-9.3,20.2h-13.5l41.2-88.5l39.7,88.5h-13.8L542.2,533.7z M537.1,521.9l-12.3-28.3l-12.9,28.3H537.1z"/>
                                        <path d="M672.8,503.9l32.4-33.2H722l-37.6,37.7l37.9,45.6h-16.8l-30-37l-2.6,2.6v34.5h-12.6v-83.3h12.6V503.9z"/>
                                        <path d="M863.6,482.5h-33.4v20h32.4v11.8h-32.4v27.8h33.4v11.8h-45.9v-83.3h45.9V482.5z"/>
                                        <path d="M1097,470.6v50.1c0,7.2,1.2,12.4,3.5,15.7c3.5,4.8,8.4,7.2,14.7,7.2c6.4,0,11.3-2.4,14.8-7.2c2.3-3.2,3.5-8.4,3.5-15.7 v-50.1h12.6v53.6c0,8.8-2.7,16-8.2,21.7c-6.2,6.3-13.7,9.5-22.7,9.5c-8.9,0-16.5-3.2-22.6-9.5c-5.5-5.7-8.2-12.9-8.2-21.7v-53.6 H1097z"/>
                                        <path d="M1253.7,520.1v33.8h-12.6v-83.3h14.2c7,0,12.2,0.5,15.8,1.5c3.6,1,6.8,2.8,9.5,5.5c4.8,4.7,7.2,10.6,7.2,17.7 c0,7.6-2.6,13.7-7.7,18.1c-5.1,4.5-12,6.7-20.7,6.7H1253.7z M1253.7,508.4h4.7c11.5,0,17.3-4.4,17.3-13.3c0-8.6-5.9-12.9-17.8-12.9 h-4.2V508.4z"/>
                                        <path d="M1559.2,483l-10.2,6c-1.9-3.3-3.7-5.5-5.4-6.5c-1.8-1.1-4.1-1.7-7-1.7c-3.5,0-6.4,1-8.7,3c-2.3,1.9-3.4,4.4-3.4,7.3 c0,4.1,3,7.3,9.1,9.8l8.3,3.4c6.8,2.7,11.7,6.1,14.8,10c3.1,3.9,4.7,8.7,4.7,14.4c0,7.7-2.6,14-7.7,19c-5.1,5-11.5,7.5-19.1,7.5 c-7.2,0-13.2-2.1-17.9-6.4c-4.6-4.3-7.5-10.3-8.7-18.1l12.7-2.8c0.6,4.9,1.6,8.3,3,10.1c2.6,3.6,6.4,5.4,11.3,5.4 c3.9,0,7.2-1.3,9.8-3.9c2.6-2.6,3.9-5.9,3.9-10c0-1.6-0.2-3.1-0.7-4.4c-0.4-1.4-1.1-2.6-2.1-3.7c-0.9-1.2-2.2-2.2-3.7-3.2 c-1.5-1-3.3-2-5.4-2.9l-8-3.3c-11.4-4.8-17.1-11.9-17.1-21.1c0-6.3,2.4-11.5,7.2-15.7c4.8-4.2,10.7-6.4,17.8-6.4 C1546.3,469,1553.8,473.7,1559.2,483z"/>
                                        <path d="M1688.3,482.5v71.4h-12.6v-71.4h-19.1v-11.8h50.8v11.8H1688.3z"/>
                                        <path d="M1802.9,511.9c0-11.7,4.3-21.8,12.9-30.2c8.6-8.4,18.8-12.6,30.9-12.6c11.9,0,22,4.2,30.5,12.7c8.5,8.5,12.8,18.7,12.8,30.6 c0,12-4.3,22.1-12.8,30.5c-8.6,8.4-19,12.6-31.1,12.6c-10.8,0-20.4-3.7-29-11.2C1807.6,536,1802.9,525.2,1802.9,511.9z M1815.5,512.1c0,9.2,3.1,16.8,9.3,22.7c6.1,5.9,13.2,8.9,21.3,8.9c8.7,0,16.1-3,22.1-9.1c6-6.1,9-13.6,9-22.3 c0-8.9-3-16.3-8.9-22.3c-5.9-6-13.2-9.1-21.9-9.1c-8.7,0-16,3-21.9,9.1C1818.5,495.9,1815.5,503.3,1815.5,512.1z"/>
                                        <path d="M2014,518.4l25.8,35.5h-15.4l-23.8-34.1h-2.3v34.1h-12.6v-83.3h14.7c11,0,18.9,2.1,23.8,6.2c5.4,4.6,8.1,10.7,8.1,18.2 c0,5.9-1.7,11-5.1,15.2C2024,514.5,2019.6,517.2,2014,518.4z M1998.4,508.9h4c11.9,0,17.8-4.5,17.8-13.6c0-8.5-5.8-12.8-17.4-12.8 h-4.5V508.9z"/>
                                        <path d="M2181.4,482.5h-33.4v20h32.4v11.8h-32.4v27.8h33.4v11.8h-45.9v-83.3h45.9V482.5z"/>
                                    </svg>  
                                </Link>
                            </div>
                            <div className="col-md-4 grid order-md-3">
                                <div className="menu">
                                    <ul>
                                        
                                        <li>
                                            <Link to="/faq/">{language.Deutsch.faq}</Link>
                                        </li>  
                                        <li>
                                            <Link to="/empfehlungen/">{language.Deutsch.recommendations}</Link>
                                        </li> 
                                        <li>
                                            <Link to="/uber-uns/">{language.Deutsch.about_us}</Link> 
                                        </li>
                                        <li>
                                            <Link to="/lieferung/">{language.Deutsch.delivery}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header__bottom-line">
                    <div className="content">
                        <div className="row">
                            <div className="col-6 col-md-2 grid order-md-2">
                                <div className="header__open-menu" id="openItem" onClick={()=>{
                                    setOpenMenu(!openMenu)
                                }}>
                                    <img src={menu} />
                                    <span>{language.Deutsch.cosmetic_shop}</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-6 order-md-3">
                                <form  method="GET" onSubmit={()=>{
                                    navigate(`${location.pathname.indexOf("katalog") < 0 ? `/katalog/?search=2` : `${location.pathname}`}`,{replace: false});
                                }}>
                                    <input type="text" name="search" value={searchInput} onChange={(e)=>{
                                        setSearchInput(e.target.value)
                                    }} placeholder={language.Deutsch.search} /> 
                                </form>
                            </div>
                            <div className="col-md-4 grid right order-md-1">
                                <div className="header__contact-block">
                                    <div className="header__contact-item phone">
                                        <Link to="/konto/">
                                            <img src={profileCircle} height="20" />
                                            <span>{language.Deutsch.account}</span>
                                        </Link>
                                    </div>
                                    <div className="header__contact-item mail">
                                        <Link to="/gewahlt/">
                                            <img src={heart} height="20" />
                                            <span>{language.Deutsch.like}</span>
                                        </Link>
                                    </div>
                                    <div className="header__contact-item mail">
                                        <div id="to-cart__btn" onClick={() => {
                                            setOpenCart(true)
                                        }}>
                                            <img src={bag} height="20" />
                                            <span id="header-count">{language.Deutsch.cart} ({auth.user.cart.fCount})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            
            {
                openMenu ? (
                    <>
                        <div className="catalog-mobile" id="catalog-mobile">
                            <div className="content">   
                                <ul className="catalog-mobile__list"> 
                                    {
                                        GetMobNode(menuList, 0)
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="catalog-menu" id="catalog-menu">
                            <div className="content">
                                <div className="row">
                                    <div className="col-md-3 catalog-menu__product-type">
                                        <div>
                                            <ul>    
                                                {
                                                    menuList.map((item: any, index) => {
                                                        return (
                                                            <li>
                                                                <a href={`/katalog/${item.slug}/`} className={index == rightCat ? "active":""} onClick={(event)=>{
                                                                    if(index != rightCat){                      
                                                                        event.preventDefault()
                                                                        setRightCat(index)
                                                                    }
                                                                }}>{item.name}</a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-9">   
                                        {
                                            GetNode(menuList, 0)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ):""
            }

            <RightCart openCart={openCart} setOpenCart={setOpenCart} />
        </>
    )
}