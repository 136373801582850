import close from '../../image/close.svg'
import trash from '../../image/trash.svg'
import min from '../../image/min.svg'
import plass from '../../image/plass.svg'
import { language } from './language'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { Loader } from '../Loader'

export const RightCart = (props: any) => {  
    const auth = useContext(GlobalContext)   
    const [loadCart, setLoadCart] = useState(true)
    
    const LoadCart = () => {
        setLoadCart(true)
        auth.user.cart.LoadCart(auth.user.userToken, auth.user.userUid).then(function(response){
            setLoadCart(false)
        }).catch(function(error){
            setLoadCart(false)
        })
    }

    const SwitchCount = (pk: number, count: number, size: number) => {
        setLoadCart(true)
        auth.user.cart.SwitchCount(auth.user.userToken, auth.user.userUid, {pk: pk, count: count, size: size}).then(function(response){
            setLoadCart(false)
        }).catch(function(error){
            setLoadCart(false)
        })
    }

    useEffect(()=>{
        if(props.openCart){
            LoadCart()
        }
    },[props.openCart])
 
    return(
        <div id="right-cart" className={`${props.openCart ? "active" : ""}`}>
            <div className="right-cart__header">
                <div className="row">
                    <div className="col-6">
                        <h3 className="title">{language.Deutsch.cart}</h3>
                    </div>
                    <div className="col-6 right">
                        <img id="right-cart__close" src={close} onClick={()=>{
                            props.setOpenCart(false)
                        }}/>
                    </div>
                </div>
            </div>
            <div id="right-cart__list">
                <div className="cart-table">
                    <table>
                        <thead>
                            <tr>
                                <th>{language.Deutsch.goods}</th>
                                <th>{language.Deutsch.count}</th>
                                <th>{language.Deutsch.price}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody id="right-cart__body">   
                            {
                                loadCart ? (
                                    <tr>
                                        <td colSpan={3}>
                                            <div>
                                                <Loader/>
                                            </div>
                                        </td>
                                    </tr>
                                ):(
                                    auth.user.cart.list.length ? (
                                        auth.user.cart.list.map((item, index) => {
                                            return(
                                                <tr className="cart-item">
                                                    <td data-label="Item">
                                                        <div className="row">
                                                            <div className="col cart-item__image">
                                                                <img src={`https://api.vivigon.com${item.product.product?.image_list![0].image}`} />
                                                            </div>
                                                            <div className="col">
                                                                <div className="grid">
                                                                    <div className="cart-item__data">
                                                                        <h3>{item.product.product?.seller.content_seller.title} | {item.product.product?.brand.content_brand.name}</h3>
                                                                        <h2>{item.product.product?.content_product.name}</h2>
                                                                        <div className="cart-item__max-quantity">{language.Deutsch.max}: {item.size.quantity}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="cart-item_quantity-block">
                                                        <div className="center cart-item__quantity">
                                                            <button className="cart-count-btn minus" onClick={()=>{
                                                                if(item.count-1 > 0)
                                                                    SwitchCount(item.product.product!.pk, item.count-1, item.size.pk)
                                                            }}>
                                                                <div className="grid">
                                                                    <img src={min} />
                                                                </div>
                                                            </button>
                                                            <input type="number" readOnly={true} value={item.count} />
                                                            <button className="cart-count-btn plus" onClick={()=>{
                                                                if(item.count+1 <= item.size.quantity)
                                                                    SwitchCount(item.product.product!.pk, item.count+1, item.size.pk)
                                                            }}>
                                                                <div className="grid">
                                                                    <img src={plass} />
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td data-label="Price">
                                                        <div>
                                                            <div className="cart-item__price center">€{item.product.product?.finalPrice}</div>
                                                            {
                                                                item.product.product?.discount ? (
                                                                    <div className="cart-item__desc-price center">€{item.product.product?.price}</div>
                                                                ):""
                                                            }
                                                        </div>
                                                    </td>
                                                    <td data-label="" className="center" style={{width: 25}}>
                                                        <img src={trash} onClick={()=>{
                                                            setLoadCart(true)
                                                            auth.user.cart.DelInCart(auth.user.userToken, auth.user.userUid, {
                                                                pk: item.product.product!.pk, 
                                                                count: item.count+1, 
                                                                size: item.size.pk
                                                            }).then(function(response){
                                                                setLoadCart(false)
                                                            }).catch(function(error){
                                                                setLoadCart(false)
                                                            })
                                                        }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ):""
                                )
                            } 
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="right-cart__btns">

                <div className="cart-info__final-cost right-delivery">
                    {language.Deutsch.shipping}:
                    <span id="right-cart__delivery" style={{fontSize: "14px"}}>€{auth.user.cart.fCost.toFixed(2)}</span>
                </div>
                <div className="cart-info__final-cost">
                    {language.Deutsch.total}:
                    <span id="right-cart__price">€{auth.user.cart.fDescCount.toFixed(2)}</span>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div>
                            <Link to="/warenkorb/" className="btn center" onClick={()=>{
                                props.setOpenCart(false)
                            }}>
                                {language.Deutsch.toCart}
                            </Link>
                        </div>
                    </div>
                    <div className="col-6">
                        {
                            !auth.user.cart.list.length ? (
                                <div>
                                    <Link to="/warenkorb/zur-kasse/" className="btn-black center" onClick={()=>{
                                        props.setOpenCart(false)
                                    }}>
                                        {language.Deutsch.toOrder}
                                    </Link>
                                </div>
                            ):""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}