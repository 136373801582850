import { ChangeEvent, useContext, useState } from "react"
import { language } from "./language"
import { GlobalContext } from "../../context/GlobalContext"
import { PopupMsg } from "../../component/PopupMsg"

export const Password = () => {
    const auth = useContext(GlobalContext)
    const [query, setQuery] = useState(false)
    const [popup, setPopup] = useState(false)
    const [popupMsg, setPopupMsg] = useState("false")
    const [form, setForm] = useState({ 
        email: ""
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(!query){
            setQuery(true)
            auth.user.SendPassword(form.email).then(function(response){
                setForm({email: ""})
                setQuery(false)
                setPopup(true)
                setPopupMsg(response.msg == "password send" ? language.Deutsch.passowrdSend : language.Deutsch.emailNotExists)
            }).catch(function(error){
                setForm({email: ""})
                setQuery(false)
                alert("error")
            })
        }
    }
    
    return(
        <>
            <section className="form">
                <div className="content">
                    <div className="form__body">
                        <h3 className="title margin-title">
                            <span className="">{language.Deutsch.title}</span> 
                        </h3>
                        <p className="form__text">{language.Deutsch.desc}</p>
                        <form onSubmit={handleSubmit}>
                            <label className="form__input">
                                {language.Deutsch.email}
                                <input required type="email" name="email" value={form.email} onChange={changeHandler} /> 
                            </label> 

                            <button>{language.Deutsch.btnText}</button> 
                        </form>
                    </div>
                </div>
            </section>
            {
                popup ? (
                    <PopupMsg text={popupMsg} setPopup={setPopup} />
                ):""
            }
        </>
    )
}