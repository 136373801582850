export const language = {
    "Deutsch": {
        "faq": "FAQ",
        "blog": "Blog",
        "recommendations": "Empfehlungen",
        "about_us": "Über uns",
        "delivery": "Lieferung",
        "cosmetic_shop": "KOSMETIKSHOP",
        "search": "Suche",
        "account": "Konto",
        "like": "WUNSCHZETTEL",
        "cart": "Warenkorb",
        "country": "Land",
        "language": "Sprachlich"
    }
}