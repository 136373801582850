import { language } from "./language"
import banner1 from '../../image/banner-1.png'
import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Recommendations } from "../../classes"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"

export const RecommendationsPage = () => {
    const auth = useContext(GlobalContext)    
    const [recommendations, setRecommendations] = useState(new Recommendations())
    const [load, setLoad] = useState(true)


    const UpdateList = (page: number) => {
        recommendations.page = page
        setLoad(true)
        recommendations.LoadData().then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{ 
        UpdateList(recommendations.page)
    },[])

    if(load)
        return (
            <div className="catalog-loader">
                <Loader />
            </div>
        )

    return (
        <>
            <div className="breadcrumb">
                <div className="content">
                    <div className="breadcrumb-list">
                        <div className="breadcrumb-list__item">
                            <a href="/">
                                {language.Deutsch.home}
                            </a>
                        </div> 
                        <div className="breadcrumb-list__item active">
                            {language.Deutsch.recommendations}
                        </div>
                    </div>
                </div>
            </div>
         
            <section className="news-list">
                <div className="content">
                    <div className="row"> 
                        {
                            recommendations.list ? (
                                recommendations.list.length ? (
                                    recommendations.list.map((item, index) => {
                                        return(
                                            <div className="col-md-3">
                                                <div className="news-item">
                                                    <a href={`/empfehlungen/${item.slug}/`}>
                                                        <div className="news-item__image" style={{backgroundImage: `url('https://api.vivigon.com/${item.image}')`}}></div>
                                                        <h3 className="news-item__title">{item.content_recommendations.title}</h3>
                                                        <p className="news-item__desc">{item.content_recommendations.miniDesc}</p>
                                                    </a>
                                                </div>
                                            </div> 
                                        )
                                    })
                                ):""
                            ):""
                        }
                    </div>
                    
                    <Pagination 
                        page={recommendations.page}
                        pageCount={recommendations.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={UpdateList}                         
                    />      

                </div>
            </section>
        </> 
    )
}