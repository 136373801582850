import { ChangeEvent, useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { language } from "./lenguage"
import { PopupMsg } from "../../component/PopupMsg"

export const Register = () => {
    const auth = useContext(GlobalContext)
    const [query, setQuery] = useState(false)
    const [popup, setPopup] = useState(false)
    const [popupMsg, setPopupMsg] = useState("false")
    const [form, setForm] = useState({ 
        name: "",
        lastName: "", 
        email: "",
        birthday: "",
        password1: "",
        password2: ""
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(!query){
            setQuery(true)
            auth.user.Register(form).then(function(response){
                setForm({name: "", lastName: "", email: "", birthday: "", password1: "", password2: ""})
                setQuery(false)
                setPopup(true)
                setPopupMsg(response.msg == "user create" ? language.Deutsch.accountCreated : language.Deutsch.alreadyExists)
            }).catch(function(error){
                setForm({name: "", lastName: "", email: "", birthday: "", password1: "", password2: ""})
                setQuery(false)
                alert("error")
            })
        }
    }
    
    return (
        <>
            <section className="form">
                <div className="content">
                    <div className="form__body">
                        <h3 className="title margin-title">
                            <span><a href="/autorisation/">{language.Deutsch.login}</a></span>
                            <span className="active">{language.Deutsch.register}</span>
                        </h3>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form__input right">
                                        {language.Deutsch.name}*
                                        <input required type="text" name="name" value={form.name} onChange={changeHandler} />
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className="form__input  left">
                                        {language.Deutsch.lname}*
                                        <input required type="text" name="lastName" value={form.lastName} onChange={changeHandler} /> 
                                    </label>
                                </div>
                            </div>

                            <label className="form__input">
                                {language.Deutsch.email}*
                                <input required type="email" name="email" value={form.email} onChange={changeHandler} /> 
                            </label>

                            <label className="form__input">
                                {language.Deutsch.birthday}*
                                <input required type="date" name="birthday" value={form.birthday} onChange={changeHandler} /> 
                            </label>

                            <label className="form__input">
                                {language.Deutsch.password}*
                                <input required type="password" name="password1" value={form.password1} onChange={changeHandler} />  
                            </label>

                            <label className="form__input">
                                {language.Deutsch.cpassword} *
                                <input required type="password" name="password2" value={form.password2} onChange={changeHandler} />  
                            </label>

                            <label className="form__checkbox">
                                <input required type="checkbox" />
                                {language.Deutsch.politic}
                            </label>

                            <button>{language.Deutsch.formBtn}</button>
                        </form>
                    </div>
                </div>
            </section>
            {
                popup ? (
                    <PopupMsg text={popupMsg} setPopup={setPopup} />
                ):""
            }
        </>
    )
}