import { useContext, useEffect, useState } from "react"
import { Loader } from "../../component/Loader"
import { CatalogProducts } from "../../classes"
import { language } from "./language"
import { Selector } from "../../component/Selector"
import { Product } from "../../component/Product"
import { Pagination } from "../../component/Pagination"
import { useParams } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalContext"

export const TradeOffers = () => {
    const auth = useContext(GlobalContext)    
    const [products, setProducts] = useState(new CatalogProducts())
    const [load, setLoad] = useState(true)
    const [formSort, setFormSort] = useState("expensiveFirst")
    const { slug } = useParams();

    const UpdateList = (page: number) => {
        products.page = page
        setLoad(true)
        if(slug)
            products.LoadTradeOffers(slug, formSort, auth.user.country.value).then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
    }

    useEffect(()=>{ 
        UpdateList(products.page)
    },[])

    if(load)
        return (
            <div className="catalog-loader">
                <Loader />
            </div>
        )

        
    return (
        <>
            <div className="breadcrumb">
                <div className="content">
                    <div className="breadcrumb-list">

                        <div className="breadcrumb-list__item">
                            <a href="/">
                                {language.Deutsch.home}
                            </a>
                        </div>
                        <div className="breadcrumb-list__item active">
                                {language.Deutsch.top}
                        </div>
                    </div>
                </div>
            </div>

            <section className="products-list catalog">
                <div className="content big-padding__btn">
                    <h3 className="title margin-title">
                        {language.Deutsch.top}
                    </h3> 
                    <div className="catalog__filter">
                        <div id="catalog__filter">
                            <div className="row">  
                                <div className="col-md-3">
                                    <label>
                                        {language.Deutsch.sort}:            
                                        <Selector list={[
                                            {
                                                "title": language.Deutsch.expensiveFirst,
                                                "value": "expensiveFirst"
                                            },
                                            {
                                                "title": language.Deutsch.cheapFirst,
                                                "value": "cheapFirst"
                                            },
                                            {
                                                "title": language.Deutsch.bestsellersFirst,
                                                "value": "bestsellersFirst"
                                            },
                                            {
                                                "title": language.Deutsch.bestReview,
                                                "value": "bestReview"
                                            }
                                        ]} value={formSort} Set={setFormSort} />        
                                    </label>
                                </div>
                            </div> 

                            <p className="catalog__filter-btns">
                                <button 
                                    onClick={()=>{
                                        UpdateList(products.page)
                                    }}
                                >
                                    {language.Deutsch.search}
                                </button>
                                <span 
                                    style={{
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setFormSort("")
                                        UpdateList(products.page)
                                    }}
                                >{language.Deutsch.clear}</span>
                            </p>
                        </div>
                    </div> 
                    <div className="row">
                        {
                            products.list ? (
                                products.list.length ? (
                                    products.list.map((item, index)=>{
                                        return(
                                            <div key={index} className="col-md-6 col-lg-3">
                                                <div className="slider__item"> 
                                                    <Product item={item} />
                                                </div>
                                            </div>
                                        )
                                    })
                                ):""
                            ):""
                        }
                    </div>  
                    
                    <Pagination 
                        page={products.page}
                        pageCount={products.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={UpdateList}                         
                    />       
                </div>
            </section>

        </>
    )
}