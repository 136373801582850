import { ChangeEvent, useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { language } from "./language"
import { PopupMsg } from "../../component/PopupMsg"

export const Login = () => {
    const auth = useContext(GlobalContext)
    const [query, setQuery] = useState(false)
    const [popup, setPopup] = useState(false)
    const [popupMsg, setPopupMsg] = useState("false")
    const [form, setForm] = useState({ 
        email: "",
        password: ""
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(!query){
            setQuery(true)
            auth.user.Login(form.email, form.password).then(function(response){
                setQuery(false)
                if(response.error == false){
                    setForm({email: "", password: ""})
                    window.location.replace("/")
                }else{
                    if(response.msg == "login error"){
                        setPopup(true)
                        setPopupMsg(language.Deutsch.loginError)
                    } 
                } 
            }).catch(function(error){
                setForm({email: "", password: ""})
                setQuery(false)
                alert("error")
            })
        }
    }

    return (
        <>
            <section className="form">
                <div className="content">
                    <div className="form__body">
                        <h3 className="title margin-title">
                            <span className="active">{language.Deutsch.login}</span>
                            <span><a href="/registrierung/">{language.Deutsch.register}</a></span>
                        </h3>
                        <form onSubmit={handleSubmit}>
                            <label className="form__input">
                                {language.Deutsch.email} 
                                <input required type="email" name="email" value={form.email} onChange={changeHandler} /> 
                            </label>
                            <label className="form__input">
                                {language.Deutsch.password}
                                <span>
                                    <a href="/passwort/">{language.Deutsch.confPassword}</a>
                                </span>
                                <input required type="password" name="password" value={form.password} onChange={changeHandler} />  
                            </label>

                            <button>{language.Deutsch.enter}</button>
                        </form>
                    </div>
                </div>
            </section>
            {
                popup ? (
                    <PopupMsg text={popupMsg} setPopup={setPopup} />
                ):""
            }
        </>
    )
}