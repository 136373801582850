export const language = {
    "Deutsch": {
        "cart": "MEINWARENKORB",
        "goods": "Ware",
        "count": "Anzahl",
        "price": "Preis",
        "max": "Maximum",
        "shipping": "Versandkosten",
        "total": "Bestellsumme",
        "toCart": "Zum Warenkorb",
        "toOrder": "Zur Kasse"
    }
}