export const language = {
    "Deutsch": {
        "followUs": "Folgen Sie uns",
        "contactUs": "KONTAKTIEREN SIE UNS",
        "customerService": "KUNDENSERVICE",
        "faq": "FAQ",
        "blog": "Blog",
        "recommendations": "Empfehlungen",
        "about_us": "Über uns",
        "delivery": "Lieferung",
        "shop": "SHOP",
        "subscribe": "UNSERE NEWSLETTER ABONNIEREN",
        "subscribeText": "Erhalten Sie unsere neuesten Updates über unsere Produkte und Angebote.",
        "subscribeEmail": "Email address",
        "subscribeBtn": "SUBSCRIBE",
        "subscribeAnswer": "Das Abonnement ist abgeschlossen"
    }
}

