import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Selector } from "../../component/Selector"
import { language } from "./language"
import { GlobalContext } from "../../context/GlobalContext"

export const Order = () => {
    const auth = useContext(GlobalContext)   
    const [loadCart, setLoadCart] = useState(true)

    const [formBrand, setFormBrand] = useState("") //Нужно удалить 

    const [form, setForm] = useState({
        fName: "",
        lName: "",
        userType: "fizUser",

        payNumber: "",
        company: "",
        taxNumber: "",

        region: "",
        city: "",
        street: "",
        apartment: "",
        index: "",
        phone: "",
        email: "",
        comment: "",
        сountry: "test"
    })

    useEffect(()=>{
        if(!auth.user.cart.list.length){
            window.location.replace('/warenkorb/')
        }
    },[])


    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        console.log(form)
        auth.user.cart.CreateOrder(auth.user.userToken, auth.user.userUid, form).then(function(response){
            if(!response.error){
                window.location.replace(response.url)
            }
            console.log("good")
        }).catch(function(error){
            console.log("error")
        })
    }

    return (
        <>
            <div className="breadcrumb">
                <div className="content">
                    <div className="breadcrumb-list">
                        <div className="breadcrumb-list__item">
                            <a href="/">
                                {language.Deutsch.home}
                            </a>
                        </div>
                        <div className="breadcrumb-list__item">
                            <a href="/warenkorb/">
                                {language.Deutsch.cart}
                            </a>
                        </div>
                        <div className="breadcrumb-list__item active">
                            {language.Deutsch.order}
                        </div>
                    </div>
                </div>
            </div>

            <section className="checkout form">
                <div className="content big-padding__btn">
                    <form className=""  onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="checkout__main-form">
                                    <h3 className="title margin-title">{language.Deutsch.chackoutTitle}</h3> 
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="form__input right">
                                                {language.Deutsch.name}* 
                                                <input type="text" name="fName" required={true} value={form.fName} onChange={changeHandler} />
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form__input left">
                                                {language.Deutsch.lName}*
                                                <input type="text" name="lName" required={true} value={form.lName} onChange={changeHandler} />
                                            </label>
                                        </div>
                                    </div>
 
                                    <label>
                                        {language.Deutsch.userType}  
                                        <Selector 
                                            list={[
                                                {
                                                    "title": language.Deutsch.fizUser,
                                                    "value": "fizUser"
                                                },
                                                {
                                                    "title": language.Deutsch.bizUser,
                                                    "value": "bizUser"
                                                }
                                            ]} 
                                            value={form.userType} Set={(value:string)=>{
                                                setForm({ ...form, ["userType"]: value })
                                            }}
                                        />             
                                    </label>  

                                    {
                                        form.userType == "bizUser" ? (
                                            <div>
                                                <label className="form__input">
                                                    {language.Deutsch.paymentAccount}* 
                                                    <input type="text" name="payNumber" required={true} value={form.payNumber} onChange={changeHandler} />
                                                </label>
        
                                                <label className="form__input">
                                                    {language.Deutsch.company}* 
                                                    <input type="text" name="company" required={true} value={form.company} onChange={changeHandler} />
                                                </label>
        
                                                <label className="form__input">
                                                    {language.Deutsch.fiscalCode}*
                                                    <input type="text" name="taxNumber" required={true} value={form.taxNumber} onChange={changeHandler} />
                                                </label>
                                            </div>
                                        ):""
                                    }
 
                                    <label style={{display: "none"}}>
                                        {language.Deutsch.deliveryProfile}  
                                        <Selector 
                                            list={[
                                                {
                                                    "title": "1",
                                                    "value": "2"
                                                },
                                                {
                                                    "title": "1",
                                                    "value": "2"
                                                }
                                            ]} 
                                            value={formBrand} Set={setFormBrand}
                                        />        
                                    </label> 

                                    <label className="form__input">
                                        {language.Deutsch.region}*   
                                        <input type="text" name="region" required={true} value={form.region} onChange={changeHandler} />
                                    </label> 

                                <label className="form__input">
                                    {language.Deutsch.city}*
                                    <input type="text" name="city" required={true} value={form.city} onChange={changeHandler} />
                                </label>

                                <label className="form__input">
                                    {language.Deutsch.street}*
                                    <input type="text" name="street" required={true} value={form.street} onChange={changeHandler} />
                                </label>

                                <label className="form__input">
                                    {language.Deutsch.apartment}*
                                    <input type="text" name="apartment" required={true} value={form.apartment} onChange={changeHandler} />
                                </label>

                                <label className="form__input">
                                    {language.Deutsch.postCode}*
                                    <input type="text" name="index" required={true} value={form.index} onChange={changeHandler} />
                                </label>
                                
                                
                                <label className="form__input">
                                    {language.Deutsch.phone}*
                                    <input type="text" name="phone" required={true} value={form.phone} onChange={changeHandler} />
                                </label>

                                <label className="form__input">
                                    {language.Deutsch.email}*
                                    <input type="mail" name="email" required={true} value={form.email} onChange={changeHandler} />
                                </label>

                                <h3 className="title margin-title">{language.Deutsch.additional}</h3> 
                                <label className="form__textarea">
                                    {language.Deutsch.orderComment}
                                    <textarea name="comment" required={false} value={form.comment} onChange={(e)=>{
                                        setForm({ ...form, ["comment"]: e.target.value })
                                    }} />
                                </label>
                            </div> 
                        </div>

                        <div className="col-md-4">
                            <div className="checkout-info">  
                                <div className="checkout-info__header"></div>                      
                                <div className="checkout-info__content">
                                    <h3 className="title">{language.Deutsch.orderInfoTitle}</h3> 
                                    <div className="checkout-info__cart"> 
                                        <div className="cart-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>{language.Deutsch.product}</th>
                                                        <th>{language.Deutsch.count}</th>
                                                        <th>{language.Deutsch.price}</th>
                                                    </tr>
                                                </thead>
                                                <tbody> 
                                                    {
                                                        auth.user.cart.list.length ? (
                                                            auth.user.cart.list.map((item, index) => {
                                                                return(
                                                                    <tr className="cart-item">
                                                                        <td data-label="Item">
                                                                            <div className="row"> 
                                                                                <div className="col">
                                                                                    <div className="grid"> 
                                                                                        <div className="cart-item__data">
                                                                                            <h3>{item.product.product?.seller.content_seller.title} | {item.product.product?.brand.content_brand.name}</h3>
                                                                                            <h2>{item.product.product?.content_product.name} <span className="cart-item__title-size">({item.size.name})</span></h2>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="cart-item_quantity-block">
                                                                            <div className="cart-item__quantity"> 
                                                                                <input type="number" value={item.count} readOnly={true} /> 
                                                                            </div>
                                                                        </td>
                                                                        <td data-label="Price">
                                                                            <div>
                                                                                <div className="cart-item__price center">€{item.product.product?.finalPrice}</div>
                                                                                {
                                                                                    item.product.product?.discount ? (
                                                                                        <div className="cart-item__desc-price center">€{item.product.product?.price}</div>
                                                                                    ):""
                                                                                }
                                                                            </div>
                                                                        </td> 
                                                                    </tr>  
                                                                )
                                                            })

                                                        ):""
                                                    }                                                     
                                                </tbody>
                                            </table>
                                        </div>
   
                                        <div className="cart-info__lines">
                                            <p className="cart-info__line">
                                                {language.Deutsch.weight}: <span>{auth.user.cart.fWeight} gramm</span>
                                            </p>
            
                                            <p style={{display: "none"}} className="cart-info__line">
                                                {language.Deutsch.shipping}: <span>€</span>
                                            </p>

                                            <p className="cart-info__line">
                                                {language.Deutsch.subtotal}: <span>€{auth.user.cart.fCost}</span>
                                            </p> 
                                            <p className="cart-info__line">
                                                {language.Deutsch.shippingDiscount}: <span className="red">€{auth.user.cart.fCost - auth.user.cart.fDescCount}</span>
                                            </p> 
                                        </div>
                                        <div className="cart-info__final-cost">
                                            {language.Deutsch.total}:
                                            <span>€{auth.user.cart.fDescCount}</span>
                                        </div>
                                    </div>
                                    <h3 className="title">{language.Deutsch.payment}</h3>
                                    <p>{language.Deutsch.paymentText1}</p>
                                    <p>{language.Deutsch.paymentText2}</p>
                            
                                    <label className="form__checkbox">
                                        <input required={true} type="checkbox" />
                                        <span style={{display: "contents"}} dangerouslySetInnerHTML={{ __html: language.Deutsch.polit }}></span>
                                    </label>

                                    <label className="form__checkbox">
                                        <input required={true} type="checkbox" name="saveDelivery" />
                                        {language.Deutsch.saveDelivery}
                                    </label>

                                    <button>{language.Deutsch.placeBtn}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        </>
    )
}