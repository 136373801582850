import { Link } from "react-router-dom";

export const language = {
    "Deutsch": {
        "login": "Einloggen",
        "register": "Ein Konto erstellen",
        "name": "Vorname",
        "lname": "Nachname",
        "email": "Email",
        "birthday": "Geburtstag",
        "password": "Passwort",
        "cpassword": "Passwort bestätigen",
        "formBtn": "Ein Konto erstellen",
        "accountCreated": "Konto erstellt",
        "alreadyExists": "Der Benutzer existiert bereits",
        "politic": (<>Ich stimme den <Link to={""}>Nutzungsbedingungen</Link> und der <Link to={""}>Datenschutzerklärung zu</Link></>)
    }
}