import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { language } from "../language"
import { useParams } from "react-router-dom";
import { Loader } from "../../../component/Loader";
import { RecommendationsItem } from "../../../classes";
import { Product } from "../../../component/Product";

export const RecommendationItemPage = () => {
    const auth = useContext(GlobalContext)    
    const { slug } = useParams();
    const [load, setLoad] = useState(true)

    const [recommendation, setRecommendation] = useState(new RecommendationsItem())

    useEffect(()=>{
        if(slug)
            recommendation.LoadData(slug).then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
    },[])
    
    if(load)
        return (
            <div className="catalog-loader">
                <Loader />
            </div>
        )

    return(
        <>     
            <div className="breadcrumb">
                <div className="content">
                    <div className="breadcrumb-list">
                        <div className="breadcrumb-list__item">
                            <a href="/">
                                {language.Deutsch.home}
                            </a>
                        </div> 
                        <div className="breadcrumb-list__item">
                            <a href="/empfehlungen/">
                                {language.Deutsch.recommendations}
                            </a>
                        </div> 
                        <div className="breadcrumb-list__item active">
                            {recommendation.content_recommendations.title}
                        </div>
                    </div>
                </div>
            </div>


            <section className="news-target">
                <div className="content">
                    <h1 className="title">{recommendation.content_recommendations.title}</h1> 
                    <div className="news-target__data"> 
                        {recommendation.content_recommendations.content}
                    </div>
                    <div className="row" style={{paddingTop: "30px"}}> 
                        {
                            recommendation.list ? (
                                recommendation.list.length ? (
                                    recommendation.list.map((item, index) => {
                                        return(
                                            <div className="col-md-6 col-lg-3">
                                                <div className="slider__item"> 
                                                    <Product item={item} />
                                                </div>
                                            </div>
                                        )    
                                    })
                                ):""
                            ):""
                        }

                    </div>
                </div>
            </section>
        </>
    )
}