import Slider from "react-slick"
import { Link } from "react-router-dom";
import { language } from "./language";

export const MainSlider = () => {
    
    const content = [
        {
            "title": "Explore the<br/>complexion products",
            "desc": "Professional makeup arists have relied of for years",
            "link": ""
        }
    ]
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <Slider {...settings}>
            {
                content.map((item, index) => {
                    return(
                        <div className="header-slider__wrapper">
                            <div className="header-slider__item-01">
                                <div className="content grid">
                                    <div>
                                        <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                                        <p className="header-slider__subtitle">{item.desc}</p>
                                        <p className="header-slider__btn">
                                            <Link to={item.link}>{language.Deutsch.shop}</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </Slider>
    )
}