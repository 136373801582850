import React, { ChangeEvent, useContext, useState, Component } from "react"
import {Route} from 'react-router-dom'  
import { MainPage } from "./pages/MainPage"
import { Catalog } from "./pages/Catalog"
import { ProductItem } from "./pages/Product"
import { Cart } from "./pages/Cart"
import { Order } from "./pages/Order"
import { Login } from "./pages/Login"
import { Register } from "./pages/Register"
import { Password } from "./pages/Password"
import { Favourites } from "./pages/Favourites"
import { RecommendationsPage } from "./pages/Recommendations"
import { RecommendationItemPage } from "./pages/Recommendations/RecommendationItem"
import { TradeOffers } from "./pages/TradeOffers"

export const useMyRoutes = () => { 
    return (
        <>
            <Route path={`/`} element={<MainPage/>} />
            <Route path={`/katalog/`} element={<Catalog/>} />
            <Route path={`/katalog/:slug/`} element={<Catalog/>} />
            <Route path={`/gewahlt/`} element={<Favourites/>} />
            
            <Route path={`/produkt/:slug/`} element={<ProductItem />} />
            <Route path={`/handelsangebote/:slug/`} element={<TradeOffers />} />

            <Route path={`/warenkorb/`} element={<Cart/>} />
            <Route path={`/warenkorb/zur-kasse/`} element={<Order/>} />

            <Route path={`/autorisation/`} element={<Login/>} />
            <Route path={`/registrierung/`} element={<Register/>} />
            <Route path={`/passwort/`} element={<Password/>} />

            <Route path={`/empfehlungen/`} element={<RecommendationsPage/>} />
                <Route path={`/empfehlungen/:slug/`} element={<RecommendationItemPage/>} />
            
            
        </>

    )
}