import pallet from '../../image/pallet.png'
import banner1 from '../../image/banner-1.png'
import banner2 from '../../image/banner-2.png'

import { Instagram } from '../../component/Instagram'
import { TopBrand } from '../../component/TopBrand'
import { ProductSlider } from '../../component/ProductSlider'
import { language } from './language'
import { CategorySlider } from '../../component/CategorySlider/CategorySlider'
import { MainSlider } from '../../component/MainSlider'
import { useContext, useEffect, useState } from 'react'
import { CatalogProducts } from '../../classes'
import { Loader } from '../../component/Loader'
import { GlobalContext } from '../../context/GlobalContext'


export const MainPage = () => {   
    const auth = useContext(GlobalContext)    
    const [products, setProducts] = useState(new CatalogProducts())
    const [load, setLoad] = useState(true)
    
    const UpdateList = () => {
        setLoad(true)
        products.LoadMpOffers(auth.user.country.value).then(function(response){
            setLoad(false)  
        }).catch(function(error){
            alert("error")
        })
    }

 

    useEffect(()=>{ 
        UpdateList()
    },[])

    if(load)
        return (
            <div className="catalog-loader">
                <Loader />
            </div>
        )

        
    return(
        <>
            <section className="header-slider"> 
                <MainSlider />
            </section>

            <section className="categories">
                <div className="content big-padding">
                    <h3 className="title center margin-title">{language.Deutsch.catalog}</h3>
                    <CategorySlider />
                </div>
            </section>

            <section className="products-slider">
                <div className="content">
                    <h3 className="title center margin-title">{language.Deutsch.trand}</h3> 
                    <ProductSlider products={
                        products.list.filter((item, index)=>{
                            return item.product!.trending ? item : false
                        })
                    } />
                </div>
            </section>

            <section className="two-banner">
                <div className="content big-padding">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="two-banner__item">
                                <a href="/katalog/">
                                    <img src={banner1} />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="two-banner__item">
                                <a href="/katalog/">
                                    <img src={banner2} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="products-slider">
                <div className="content big-padding__btn">
                    <h3 className="title center margin-title">{language.Deutsch.bestSeller}</h3> 
                    <ProductSlider products={
                        products.list.filter((item, index)=>{
                            return item.product!.bestseller ? item : false
                        })
                    } />
                </div>
            </section> 
            
            <section className="banner">
                <div className="content">
                    <div className="row">
                        <div className="col-md-6 grid center">
                            <div>
                                <h3>{language.Deutsch.palletCreator}</h3>
                                <p>{language.Deutsch.palletText}</p>
                                <p>
                                    <a href="">{language.Deutsch.palletBtn}</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 center">
                            <img src={pallet} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="products-slider">
                <div className="content big-padding">
                    <h3 className="title center margin-title">{language.Deutsch.weRecommend}</h3> 
                    <ProductSlider products={
                        products.list.filter((item, index)=>{
                            return item.product!.recommendation ? item : false
                        })
                    } />
                </div>
            </section>

            <TopBrand />

            <Instagram />
        </>
    )
}