export const language = {
    "Deutsch": {
        "order": "ZUR KASSE",
        "cart": "WARENKORB",
        "home": "STARTSEITE",
        "chackoutTitle": "RECHNUNG UND VERSAND",
        "name": "Vorname",
        "lName": "Nachname",
        "userType": "Benutzertyp",
        "fizUser": "Privatkunde",
        "bizUser": "Geschäftskunde",
        "paymentAccount": "Zahlungskonto",
        "company": "Unternehmensname",
        "fiscalCode": "Steuernummer",
        "deliveryProfile": "Lieferprofil",
        "select": "Wählen",
        "addressList": "Adressliste",
        "region": "Land / Region",
        "city": "Stadt / Ort",
        "street": "Straße- und Hausnummer",
        "apartment": "Appartement, Suite, Einheit, usw.",
        "postCode": "PLZ",
        "phone": "Telefon",
        "email": "E-Mail-Adresse",
        "additional": "ZUSATZINFORMATION",
        "orderComment": "Bestellkommentare",
        "orderInfoTitle": "IHRE BESTELLUNG",
        "product": "Ware",
        "count": "Anzahl",
        "price": "Preis",
        "delivery": "Lieferung",
        "weight": "Gewicht",
        "shipping": "Versandkosten",
        "subtotal": "Zwischensumme",
        "shippingDiscount": "Versandrabatt",
        "total": "Bestellsumme",
        "payment": "Zahlung",
        "paymentText1": "Alle Transaktionen sind sicher und verschlüsselt",
        "paymentText2": "Ihre personenbezogenen Daten werden zur Bearbeitung Ihrer Bestellung, zur Unterstützung Ihrer Erfahrungen auf dieser Website und zu anderen in unserer Datenschutzerklärung beschriebenen Zwecken verwendet.",
        "saveDelivery": "Lieferprofil speichern",
        "placeBtn": "BESTELLUNG AUFGEBEN",
        "polit": ' Ich stimme den <a href="/datenschutzerklarung/">Nutzungsbedingungen</a> und der <a href="/datenschutzerklarung/">Datenschutzerklärung</a> zu'
    }
}