import { useEffect, useRef, useState } from "react"

export const Selector = (props: any) => {
    const [open, setOpen] = useState(false)
    const [label, setLabel] = useState(props.list[0].title)


    const myRefMenu = useRef<HTMLDivElement>(null);

    useEffect(() => {
        props.list.forEach((item: any, index: number) => {
            if(item.value == props.value)
                setLabel(item.title)
        })
    },[])


    useEffect(() => {
        const checkIfClickedOutside = (e: any) => { 
            if (open && myRefMenu.current && !myRefMenu.current.contains(e.target)) { 
                setOpen(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside) 
        return () => { 
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [open])
      
    return(
        <div className="select" ref={myRefMenu}>
            <div className="select__header" onClick={()=>{
                setOpen(!open)
            }}>
                <span className="select__current">{label}</span>   
                <div className="select__icon"></div>
            </div> 
            {
                open ? (
                    <div className="select__body">  
                        {
                            props.list ? (
                                props.list.length ? (
                                    props.list.map((item: any, index: number) => {
                                        if(item.title != "")
                                            return (
                                                <div 
                                                    className="select__item"
                                                    onClick={()=>{
                                                        setOpen(false)
                                                        setLabel(item.title)
                                                        props.Set(item.value)
                                                    }}
                                                >
                                                    {item.title}
                                                </div>
                                            )
                                    })
                                ):""
                            ):""
                        }
                    </div>
                ):""
            }
        </div>
    )
}