//import { User } from "../classes" 
import { createContext } from "react";
import { Category, User } from "../classes";
 
type GlobalContextType = {
   user: User 
   category: Category
}
export const GlobalContext = createContext<GlobalContextType>({
    user: new User,
    category: new Category
}) 