import { Link } from "react-router-dom"
import { language } from "./language"
import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"

export const Product = (props: any) => {
    const auth = useContext(GlobalContext)
    const [inFavourites, setInFavourites] = useState(auth.user.favourites.includes(props.item.product.pk))
    //{% if product.pk in favouritesList %}active{% endif %}

    const UpdateFavourites = () => {
        setInFavourites(!inFavourites) 
        auth.user.SetFavourites(props.item.product.pk).then(function(response){
            console.log("test")
        }).catch(function(error){
            console.log("error")
        })
    }

    return (
        <div className="products-item">  
            <div className={`product-item__like product-item__like-btn ${inFavourites ? "active":""}`} onClick={UpdateFavourites}></div>
            <Link to={`/produkt/${props.item.product.slug}/`}>
                <div className="products-item__sticker">
                    {
                        props.item.product.discount > 0 ? (
                            <div className="products-item__sticker-item">
                                <div>-{props.item.product.discount}%</div>
                            </div>
                        ):""
                    }
                    {
                        props.item.product.newSticker ? (
                            <div className="products-item__sticker-item">
                                <div>NEW</div>
                            </div>
                        ):""
                    }
                </div>
                <div className="products-item__image grid center" style={{
                    backgroundImage: `url(https://api.vivigon.com${props.item.product.image_list[0].image})`
                }}> 
                </div>
            </Link>
            <div className="row"> 
                <div className="col-md-6 products-item__brand-name">
                    <Link to={`/lieferer/${props.item.product.seller!.slug}/`}>{props.item.product.seller!.content_seller.title}</Link> | <Link to={`/luxusmarken/${props.item.product.brand!.slug}/`}>{props.item.product.brand!.content_brand.name}</Link>
                </div>
                <div className="col-md-6 right">
                    <svg className={`star ${props.item.product.rating >= 1 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                    <svg className={`star ${props.item.product.rating >= 2 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                    <svg className={`star ${props.item.product.rating >= 3 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                    <svg className={`star ${props.item.product.rating >= 4 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                    <svg className={`star ${props.item.product.rating >= 5 ? "active":""}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"></path></svg>
                </div>
            </div>                            
            <Link to={`/produkt/${props.item.product.slug}/`}>
                <h3>{props.item.product.content_product.name}</h3>
            </Link>
            <div className="row products-item__price-line">
                <div className="col-md-6 products-item__price">
                    <Link to={`/produkt/${props.item.product.slug}/`}>
                        €{props.item.product.finalPrice}
                        {
                            props.item.product.discount > 0 ? (
                                <span>{props.item.product.price}</span>   
                            ):""
                        }  
                    </Link> 
                </div>
                <div className="col-md-6 right products-item__to-cart">
                    <Link to={`/produkt/${props.item.product.slug}/`}>
                        <span className="product-to-cart">+ {language.Deutsch.btn}</span>
                    </Link> 
                </div>
            </div>
        </div>
    )
}