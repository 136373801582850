import categories01 from '../../image/categories_01.png'
import categories02 from '../../image/categories_02.png'
import categories03 from '../../image/categories_03.png'
import categories04 from '../../image/categories_04.png'
import categories05 from '../../image/categories_05.png'

import Slider from "react-slick"
import { Link } from "react-router-dom";

export const CategorySlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1
    }

    const category = [
        {
            "title": "HAARKUNST",
            "image": categories01,
            "link": "/katalog/haarkunst/"
        },
        {
            "title": "MAKE-UP",
            "image": categories04,
            "link": "/katalog/make-up/"
        },
        {
            "title": "MANIKÜRE, PEDIKÜRE",
            "image": categories03,
            "link": "/katalog/manikure-pedikure/"
        },
        {
            "title": "Esthetics",
            "image": categories02,
            "link": "/katalog/asthetische-kosmetologie/"
        },
        {
            "title": "ELEKTROEPILATION",
            "image": categories05,
            "link": "/katalog/elektroepilation/"
        },
    ]

    return (
        <Slider {...settings}>
            {
                category.map((item, index) => {
                    return(
                        <Link to={item.link}>
                            <div className="" style={{
                                backgroundImage: `url('${item.image}')`
                            }}>
                                <h3>{item.title}</h3>
                            </div>
                        </Link>
                    )
                })
            }
        </Slider>
    )
}