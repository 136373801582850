export const language = {
    "Deutsch": {
        "weRecommend": "WIR EMPFEHLEN AUCH",
        "palletCreator": "PALETTEN-ERSTELLER",
        "palletBtn": "Palette erstellen",
        "palletText": "Erstellen Sie Ihre Traum Rouge- und Lidschatten-Palette.<br/>Wählen Sie Ihre Lieblingsfarbtöne und legen Sie sie in unsere nachfüllbare kompakte Palette.",
        "bestSeller": "BESTSELLER",
        "trand": "TRENDS",
        "catalog": "Katalog"
    }
}