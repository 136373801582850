import { Link, useParams } from "react-router-dom";
import { Product } from "../../component/Product"
import { ProductSlider } from "../../component/ProductSlider"
//import { language } from "./language"
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { BreadItemType, CatalogProducts } from "../../classes";
import { Selector } from "../../component/Selector";
import { FilterInput } from "../../component/FilterInput";
import { Pagination } from "../../component/Pagination";
import { Loader } from "../../component/Loader";
import { language } from "./language";

export const Favourites = () => { 
    const auth = useContext(GlobalContext)   
    const [load, setLoad] = useState(true)
    const [products, setProducts] = useState(new CatalogProducts())


    const UpdateList = () => { 
        setLoad(true)
        products.LoadFavourites(auth.user.favourites, auth.user.country.value).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{ 
        UpdateList()
    },[])

    if(load)
        return (
            <div className="catalog-loader">
                <Loader />
            </div>
        )

    return(
        <>
            <div className="breadcrumb">
                <div className="content">
                    <div className="breadcrumb-list">
                        <div className="breadcrumb-list__item">
                            <a href="/">
                                {language.Deutsch.home}
                            </a>
                        </div>
                        <div className="breadcrumb-list__item active">
                            {language.Deutsch.favourites}
                        </div>
                    </div>
                </div>
            </div>

            <section className="products-list catalog">
                <div className="content big-padding__btn">
                    <h3 className="title margin-title">{language.Deutsch.favourites}</h3> 
                    <div className="row">
                        {
                            products.list ? (
                                products.list.length ? (
                                    products.list.map((item, index)=>{
                                        return(
                                            <div key={index} className="col-md-6 col-lg-3">
                                                <div className="slider__item"> 
                                                    <Product item={item} />
                                                </div>
                                            </div>
                                        )
                                    })
                                ):""
                            ):""
                        }
                    </div>  

                    
                    <Pagination 
                        page={products.page}
                        pageCount={products.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={UpdateList}                         
                    />          
                </div>
            </section>
        </>
    )
}