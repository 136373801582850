import axios from 'axios'
import { country } from './country'
import { error } from 'console'
import { v4 as uuidv4 } from 'uuid';

const host = "https://api.vivigon.com/"

export interface BreadItemType {
    id: number
    level: number
    parent: number
    slug: string
    content_category: [{
        name: string
    }]
}

export interface ProductType  { 
    pk: number,
    rating: number,
    content_product: {
        name: string,
        content: string
    },
    category?: {
        slug: string,
        content_category: {
            name: string,
            content: string
        }
    },
    price: number,
    discount: number,
    finalPrice: number,
    selerBarcode?: string,
    manufacturerBarcode?: string,
    slug: string,
    size_list?: [
        {
            pk: number,
            name: string,
            quantity: number
        }
    ],
    seller: {
        slug: string,
        content_seller: {
            title: string
        }
    },
    reviews_list?: [
        {
            text: string,
            rating: number
        }
    ],
    brand: {
        slug: string,
        content_brand: {
            name: string
        }
    },
    quantity?: number,
    sellCount?: number,
    weight: number,
    newSticker: boolean,
    trending: boolean,
    bestseller: boolean,
    recommendation: boolean,

    status?: string,
    regionLimited?: boolean,
    country?: {
        name: string,
        flag: string
    },
    image_list?: [
        {
            image: string,
            sort: number
        }
    ],
    props_list?: [
        {
            name: string,
            value: string
        }
    ]
}

export interface ProductOffersType {
    slug: string
    content_product: {
        name: string
        content: string
    }
    discount: number
    rating: number
    price: number
    finalPrice: number 
    seller: {
        slug: number
        content_seller: {
            title: string
        }
    },
    brand: {
        slug: string
        content_brand: {
            name: string
        }
    }
}

export interface ProductReviewType {
    text: string
    rating: number
}

export interface Recommendation {
    slug: string,
    image: string,
    content_recommendations: {
        title: string,
        miniDesc?: string,
        content?: string
    }
}

export class RecommendationsItem implements Recommendation {
    slug: string
    image: string
    content_recommendations: { title: string; miniDesc?: string; content?: string}
    list: Product[]

    constructor(data: Recommendation = {
        slug: "",
        image: "",
        content_recommendations: {
            title: "",
            miniDesc: "",
            content: "" 
        }
    }){
        this.slug = data.slug
        this.image = data.image
        this.content_recommendations = data.content_recommendations
        this.list = []
    }

    async LoadData(slug: string){
        const Hthis = this
        await axios.get(`${host}recommendations/${slug}/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.content_recommendations.title = res.data.content_recommendations.title
            Hthis.content_recommendations.content = res.data.content_recommendations.content

            res.data.products.forEach((item: any, index: number) => {
                Hthis.list.push(new Product({...item},item.image_list[0]))
            }) 
        }).catch(function(error){
            console.log(error)
        })
    }
}

export class Recommendations{
    list: RecommendationsItem[] = []
    page: number = 1
    pageCount: number = 1

    constructor(){}

    async LoadData(){
        const Hthis = this
        await axios.get(`${host}recommendations/`, {
            params: {
                'page': 1,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount

            res.data.recommendations.forEach((item: Recommendation, index: number) => {
                Hthis.list.push(new RecommendationsItem(item))
                //Hthis.list.push(new Product({...item},item.image_list[0]))
            })
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

}

export class ProductInCart{
    pk: number
    product: Product
    size: {
        pk: number,
        name: number,
        quantity: number
    }
    count: number

    constructor(
        pk: 0,
        product: Product,
        size: {
            pk: number,
            name: number,
            quantity: number
        },
        count: 0
        
    ){
        this.pk = pk
        this.product = product
        this.size = size
        this.count = count
    }
}

export class Cart{
    pk: number 
    list: ProductInCart[]
    fCost: number
    fCount: number
    fDescCount: number
    fWeight: number

    constructor(){
        this.pk = 0
        this.list = []
        this.fCost = 0
        this.fCount = 0
        this.fDescCount = 0
        this.fWeight = 0
    }

    async DelInCart(token: string, uid: string, product: {
        pk: number,
        count: number,
        size: number
    }){
        const Hthis = this  

        await axios.post(`${host}del-in-cart/`, {
            pk: product.pk,
            count: product.count,
            size: product.size
        },{
            params: {
                'uid': uid
            },
            headers: token ? {
                'Content-Type': 'application/json',
                'Authorization': '  '+token
            } : {
                'Content-Type': 'application/json',
            }
        }).then((res) => {  
            console.log(res.data)
            Hthis.UpdateList(res.data.products)
        }).catch((error) => {
            console.log(error)
        })
    }
    
    async CreateOrder(token: string, uid: string, form: {
        fName: string, lName: string, userType: string,
        payNumber: string, company: string, taxNumber: string,
        region: string, city: string, street: string,
        apartment: string, index: string, phone: string,
        email: string, comment: string, сountry: string
    }){

        let data = {
            error: true,
            msg: "",
            url: ""            
        }

        await axios.post(`${host}order/`, {
            ...form
        },{
            params: {
                'uid': uid
            },
            headers: token ? {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            } : {
                'Content-Type': 'application/json',
            }
        }).then((res) => {  
            if(res.data.error == false){
                data = {
                    error: false,
                    msg: "",
                    url: res.data.msg
                }
            }
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        })

        return data
    }

    async SwitchCount(token: string, uid: string, product: {
        pk: number,
        count: number,
        size: number
    }){
        const Hthis = this  
        
        await axios.post(`${host}switch-cart-count/`, {
            pk: product.pk,
            count: product.count,
            size: product.size
        },{
            params: {
                'uid': uid
            },
            headers: token ? {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            } : {
                'Content-Type': 'application/json',
            }
        }).then((res) => {  
            console.log(res.data)
            Hthis.UpdateList(res.data.products)
        }).catch((error) => {
            console.log(error)
        })
    }

    async AddToCart(token: string, uid: string, product: {
        pk: number,
        count: number,
        size: number
    }){
        const Hthis = this  
        
        console.log(product)
        await axios.post(`${host}add-to-cart/`, {
            pk: product.pk,
            count: product.count,
            size: product.size
        },{
            params: {
                'uid': uid
            },
            headers: token ? {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            } : {
                'Content-Type': 'application/json',
            }
        }).then((res) => {  
            console.log(res.data)
            Hthis.UpdateList(res.data.products)
        }).catch((error) => {
            console.log(error)
        })
    }

    async LoadCart(token: string, uid: string){
        const Hthis = this  
        let itemCount = 0 
        
        await axios.get(`${host}order/0/`, {
            params: {
                'uid': uid
            },
            headers: token ? {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            } : {
                'Content-Type': 'application/json',
            }
        }).then((res) => {  
            if(res.data.order){
                Hthis.pk = res.data.pk
                Hthis.UpdateList(res.data.products)
            }
        }).catch((error) => {
            
        })
        return itemCount
    }

    UpdateList(data: any){
        this.list = []
 
        data.forEach((itemInOrder: any, index: number) => {
            this.list.push(new ProductInCart(
                itemInOrder.pk,
                new Product({...itemInOrder.item},itemInOrder.item.image_list[0]),
                itemInOrder.size,
                itemInOrder.count
            ))

        })
        this.Calculate()
    }

    Calculate(){
        this.fCost = 0
        this.fCount = 0
        this.fDescCount = 0
        this.fWeight = 0

        this.list.forEach((item, number) => {
            this.fCount += item.count
            this.fCost += item.count * item.product.product!.price
            this.fDescCount += item.count * item.product.product!.finalPrice
            this.fWeight += item.count * item.product.product!.weight
        })
    }
}

export class User{
    pk: number = 0
    isAuthenticated: boolean = false
    userToken: string = ""
    userUid: string = ""

    cookie: boolean = true
    countrySelected: boolean = false
    country = country[0]
    language = "Deutsch"
    previouslyID: number[]
    previously: Product[]
    
    favourites: number[] = []

    cart: Cart

    constructor(){ 
        this.previously = []
        this.previouslyID = []
        this.cart = new Cart()
        if(localStorage.getItem("userPreviously")){
            const userProps = JSON.parse(localStorage.getItem("userPreviously") || "")
            this.previouslyID = userProps.previouslyID
        }

        if(localStorage.getItem("userProps")){
            const userProps = JSON.parse(localStorage.getItem("userProps") || "")
            this.cookie = userProps.cookie
            this.country = userProps.country
            this.userUid = userProps.userUid
            this.countrySelected = true
            this.favourites = userProps.favourites
            this.language = userProps.language
            console.log(this.userUid)
        }

        if(!this.userUid){
            this.CreateUid()
        }

        if(localStorage.getItem("userLogin")){
            const userProps = JSON.parse(localStorage.getItem("userLogin") || "")
            this.userToken = userProps.userToken
            if(this.userToken){
                this.isAuthenticated = true
            }
        }
    }

    AddToPreviously(id: number){ 
        if(this.previouslyID.indexOf(id) == -1){
            this.previouslyID.unshift(id)
            this.previouslyID = this.previouslyID.slice(0, 5);
            localStorage.setItem("userPreviously", JSON.stringify({
                "previouslyID": this.previouslyID
            }))
            return true
        }
        return false
    }

    async GetPreviously(){ 
        const Hthis = this
        await axios.get(`${host}previously/`, {
            params: {

            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            Hthis.previously = []
            res.data.products.forEach((item: any, index: number) => {
                Hthis.previously.push(new Product({...item},item.image_list[0]))
            })
        }).catch(function(error){
            console.log(error)
        })
    }

    async SetFavourites(id: number){ 
        if(this.favourites.includes(id)){
            this.favourites = this.favourites.filter(function( obj ) {
                return obj != id
            })
        }else{
            this.favourites.push(id)
        }

        localStorage.setItem("userProps", JSON.stringify({
            "cookie": this.cookie,
            "country": this.country,
            "userUid": this.userUid,
            "countrySelected": this.countrySelected,
            "favourites": this.favourites,
            "language": this.language
        }))

        if(this.isAuthenticated){
            axios.post(`${host}favourites/`, {}, {
                params: {
                    'id': id
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+this.userToken
                }
            }).then((res) => {
                console.log(res.data)
            }).catch(function(error){
                console.log(error)
            })
        }
    }

    async GetFavourites(){
        if(this.isAuthenticated){
            const Hthis = this
            await axios.get(`${host}favourites/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+this.userToken
                }
            }).then((res) => { 
                Hthis.favourites = []
                Hthis.favourites = res.data.products.favourites
                
                localStorage.setItem("userProps", JSON.stringify({
                    "cookie": Hthis.cookie,
                    "country": Hthis.country,
                    "userUid": Hthis.userUid,
                    "countrySelected": Hthis.countrySelected,
                    "favourites": Hthis.favourites,
                    "language": this.language
                }))
            }).catch(function(error){
                console.log(error)
            })
        }else{
            if(localStorage.getItem("userProps")){
                const userProps = JSON.parse(localStorage.getItem("userProps") || "")
                this.favourites = userProps.favourites
            }
        }
    }

    ConfCookie(){
        this.cookie = false
        localStorage.setItem("userProps", JSON.stringify({
            "cookie": this.cookie,
            "country": this.country,
            "userUid": this.userUid,
            "countrySelected": this.countrySelected,
            "favourites": this.favourites,
            "language": this.language
        }))
    }

    ConfCountry(){
        localStorage.setItem("userProps", JSON.stringify({
            "cookie": this.cookie,
            "country": this.country,
            "userUid": this.userUid,
            "countrySelected": this.countrySelected,
            "favourites": this.favourites,
            "language": this.language
        }))
    }

    async Login(email: string, password: string){
        let data = {
            error: true,
            msg: "error"
        }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(password))
        return {
            "error": true,
            "msg": "login error"
        }
        
        const Hthis = this
        await axios.post(`${host}login/`, {
            email: email,
            password: password
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            if(res.data.error == false){
                localStorage.setItem("userLogin", JSON.stringify({
                    "token": res.data.msg.userToken,
                }))

                Hthis.userToken = res.data.msg.userToken
                data = {
                    error: false,
                    msg: ""
                }
            }else{
                data = {
                    error: true,
                    msg: res.data.msg
                }
            }
            console.log(data)
        }).catch(function(error){
            console.log(error)
        })
        return data

    }

    async SendPassword(email: string){
        let data = {
            error: true,
            msg: "error"
        }

        await axios.post(`${host}sendpassword/`, {
            email: email
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            data = res.data
            console.log(data)
        }).catch(function(error){
            console.log(error)
        })
        return data
        
    }

    async Register(form: {name: string, lastName: string, birthday: string, email: string, password1: string, password2: string}){
        let data = {
            error: true,
            msg: "error"
        }

        if(form.password1 != form.password2)
            return {
                "error": true,
                "msg": "Пароли должны совпадать"
            }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(form.password1))
            return {
                "error": true,
                "msg": "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
            }
        
        await axios.post(`${host}registration/`, form, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            data = res.data
            console.log(data)
        }).catch(function(error){
            console.log(error)
        })
        return data
    }

    CreateUid(){
        const { v4: uuidv4 } = require('uuid')
        this.userUid = `${uuidv4()}-${Date.now()}`

        localStorage.setItem("userProps", JSON.stringify({
            "cookie": this.cookie,
            "country": this.country,
            "userUid": this.userUid,
            "countrySelected": this.countrySelected,
            "favourites": this.favourites,
            "language": this.language
        }))
    }
}

export class Category{
    treeList: {}[]
    defList: BreadItemType[]
    breadcrumbs: BreadItemType[]
    
    constructor(){
        this.treeList = []
        this.defList = []
        this.breadcrumbs = []
    }

    async GetCategory(){
        const Hthis = this
        await axios.get(`${host}category/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            console.log(res.data)
            Hthis.defList = res.data.category
            Hthis.treeList = GetNode(res.data.category, null)  
        }).catch(function(error){
            console.log(error)
        })
        return {
            "category": this.treeList,
            "favourites": []
        }
    }

    async GetBreadcrumbs(target: string){ 
        let pID = 0
        this.breadcrumbs = []
        this.breadcrumbs.shift()

        const fItem = this.defList.filter((item: BreadItemType) => item.slug == target)
        this.breadcrumbs.push(fItem[0])
        pID = fItem[0].parent

        for(let i=fItem[0].level; i>0; i--){
            const fItem2 = this.defList.filter((item: BreadItemType) => item.id == pID)
            pID = fItem2[0].parent
            this.breadcrumbs.unshift(fItem2[0])   
        }
 
        return this.breadcrumbs
    }
}

export class Product{
    product?: ProductType
    offers: ProductOffersType[]
    review: ProductReviewType[]
    pageCount = 1
    page = 1
    
    constructor(
        item = {
            pk: 0,
            rating: 0.0,
            content_product: {
                name: "",
                content: ""
            },
            price: 0,
            discount: 0,
            finalPrice: 0,
            weight: 0,
            slug: "",
            newSticker: false,
            trending: false,
            seller: {
                slug: "",
                content_seller: {
                    title: ""
                }
            },
            brand: {
                slug: "",
                content_brand: {
                    name: ""
                }
            },
            bestseller: false,
            recommendation: false,
        },
        
        image_list = {
            image: "",
            sort: 0
        },
    ){
        this.offers = []
        this.review = []

        this.product = {...item}
        this.product.image_list?.push(image_list)
    }

    async LoadData(slug: string, country: string){
        const Hthis = this
        await axios.get(`${host}product/${slug}/`, {
            params: {
                'country': country
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            Hthis.product = {...res.data.product}
            Hthis.offers = res.data.offers
        }).catch(function(error){
            console.log(error)
        })
    }

    async LoadReview(page: number = 1){
        if (!this.product)
            return false

        this.page = page   
        const Hthis = this
        
        await axios.get(`${host}review/`, {
            params: {
                'product': Hthis.product!.pk,
                'page': page
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res)=>{
            Hthis.review = res.data.review 
            Hthis.pageCount = Math.ceil(res.data.pageCount)
        }).catch((error)=>{
            console.log(error)
        })
    }
}

export class CatalogProducts{
    list: Product[]
    brand: [{
        value: number,
        title: string
    }]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
        this.brand = [{
            value: 0,
            title: ""
        }]
        this.page = 1
        this.pageCount = 1
    }

    async LoadData(slug: string, formMinPrice: number, formMaxPrice: number, formBrand: string, formSort: string, search: string, country: string){
        const Hthis = this
        await axios.get(`${host}product/?page=${Hthis.page}`, {
            params: {
                'country': country,
                'slug': slug,
                'formMinPrice': formMinPrice,
                'formMaxPrice': formMaxPrice,
                'formBrand': formBrand,
                'formSort': formSort,
                'search': search
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.brand = [{
                value: 0,
                title: ""
            }]
            res.data.products.forEach((item: any, index: number) => {
                Hthis.list.push(new Product({...item},item.image_list[0]))
            })
            Hthis.pageCount = res.data.pageCount
            res.data.brand.forEach((item: any, index: number) => {
                Hthis.brand.push({
                    value: item.brand__pk,
                    title: item.brand__content_brand__name
                })
            })
        }).catch(function(error){
            console.log(error)
        })
    }

    async LoadFavourites(ids: number[], country: string){
        const Hthis = this
        await axios.post(`${host}favourites-list/?page=${Hthis.page}&country=${country}`, {
            'favourites': ids,
        }, {
            headers: {
                'Content-Type': 'application/json', 
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.brand = [{
                value: 0,
                title: ""
            }]
            res.data.products.forEach((item: any, index: number) => {
                Hthis.list.push(new Product({...item},item.image_list[0]))
            })
            Hthis.pageCount = res.data.pageCount
            res.data.brand.forEach((item: any, index: number) => {
                Hthis.brand.push({
                    value: item.brand__pk,
                    title: item.brand__content_brand__name
                })
            })
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

    async LoadTradeOffers(slug: string, formSort: string, country: string){
        const Hthis = this
        await axios.get(`${host}trade-offers/${slug}/?page=${Hthis.page}&country=${country}`, {
            params: { 
                'formSort': formSort, 
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            console.log("test")
            Hthis.list = []
            Hthis.brand = [{
                value: 0,
                title: ""
            }]
            res.data.products.forEach((item: any, index: number) => {
                Hthis.list.push(new Product({...item},item.image_list[0]))
            })
            Hthis.pageCount = res.data.pageCount
            res.data.brand.forEach((item: any, index: number) => {
                Hthis.brand.push({
                    value: item.brand__pk,
                    title: item.brand__content_brand__name
                })
            })
        }).catch(function(error){
            console.log(error)
        })
    }

    async LoadMpOffers(country: string){
        const Hthis = this
        await axios.get(`${host}mp-product/`, {
            params: {
                "country": country
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            console.log("test")
            Hthis.list = []
            Hthis.brand = [{
                value: 0,
                title: ""
            }]
            res.data.products.forEach((item: any, index: number) => {
                Hthis.list.push(new Product({...item},item.image_list[0]))
            })
            Hthis.pageCount = res.data.pageCount
            res.data.brand.forEach((item: any, index: number) => {
                Hthis.brand.push({
                    value: item.brand__pk,
                    title: item.brand__content_brand__name
                })
            })
        }).catch(function(error){
            console.log(error)
        })
    }
}

const GetNode = (array: [], parent: any) => {
    const nodeArray = [{}]
    nodeArray.shift()
    array.forEach((item, index) => {
        if(item["parent"] == parent)
            nodeArray.push({
                "id": item["id"],
                //"level": item["level"],
                "name": item["content_category"][0]["name"],
                //"parent": item["parent"],
                "slug": item["slug"],
                "sort": item["sort"],
                "children": GetNode(array, item["id"])
            })
    })
    return nodeArray
}