import { useState } from "react"

export const CategoryItem = (props: any) => { 

    const [operLVL2, setOperLVL2] = useState(false)
    if(props.deep == 1)
        return (
            <div className="col-md-4 col-lg-3">
                <div className="menu-row">
                    <a href={`/katalog/${props.item.slug}/`}>{props.item.name}</a>
                    {
                        props.item.children ? (
                            props.item.children.length ? (
                                <ul>
                                    {props.GetNode(props.item.children, 2)}
                                </ul>
                            ):""
                        ):""
                    }
                </div>
            </div>
        ) 

    return(
        <li className={`line-lvl${props.deep} ${props.item.children ? props.item.children.length ? " arrow-item":"":""} ${props.deep == 2 && operLVL2 == true ? " active": ""}`}>
            <a href={`/katalog/${props.item.slug}/`} onClick={(event)=>{
                if(props.deep == 2 && props.item.children){
                    if(!operLVL2){
                        event.preventDefault()
                        setOperLVL2(true)
                    }
                }
            }}>{props.item.name}</a>
            {
                props.item.children ? (
                    props.item.children.length ? (
                        <ul>
                            {props.GetNode(props.item.children, props.deep+1)}
                        </ul>
                    ):""
                ):""
            }
        </li>
    )
} 