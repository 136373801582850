import close from "../../image/close.svg"

export const PopupMsg = (props: any) => {
    return (
        <div className="popup">
            <div>
                <div className="popup-close">
                    <img src={close} onClick={()=>{
                        props.setPopup(false)
                    }} />
                </div>
                <h3>{props.text}</h3>
            </div>
        </div>
    )
}