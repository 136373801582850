import { language } from "./language"
import trash from '../../image/trash.svg'
import min from '../../image/min.svg'
import plass from '../../image/plass.svg'
import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Link } from "react-router-dom"
import { Loader } from "../../component/Loader"

export const Cart = () => {
    const auth = useContext(GlobalContext)   
    const [loadCart, setLoadCart] = useState(true)
    
    const LoadCart = () => {
        setLoadCart(true)
        auth.user.cart.LoadCart(auth.user.userToken, auth.user.userUid).then(function(response){
            setLoadCart(false)
        }).catch(function(error){
            setLoadCart(false)
        })
    }


    const SwitchCount = (pk: number, count: number, size: number) => {
        setLoadCart(true)
        auth.user.cart.SwitchCount(auth.user.userToken, auth.user.userUid, {pk: pk, count: count, size: size}).then(function(response){
            setLoadCart(false)
        }).catch(function(error){
            setLoadCart(false)
        })
    }

    useEffect(()=>{
        LoadCart()
    },[])
    
    return (
        <>
            <div className="breadcrumb">
                <div className="content">
                    <div className="breadcrumb-list">
                        <div className="breadcrumb-list__item">
                            <a href="/">
                                {language.Deutsch.home}
                            </a>
                        </div>
                        <div className="breadcrumb-list__item active">
                            {language.Deutsch.cart}
                        </div>
                    </div>
                </div>
            </div>

            <section className="cart">
                <div className="content big-padding__btn">
                    <div className="row">
                        <div className="col-md-8">
                            <h3 className="title margin-title">{language.Deutsch.cart} <span className="title__count">({auth.user.cart.fCount})</span></h3> 
                            <div className="cart-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{language.Deutsch.product}</th>
                                            <th>{language.Deutsch.count}</th>
                                            <th>{language.Deutsch.price}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>  
                                        {
                                            loadCart ? (
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div>
                                                            <Loader/>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ):(
                                                auth.user.cart.list.length ? (
                                                    auth.user.cart.list.map((item, index) => {
                                                        return(
                                                            <tr className="cart-item">
                                                                <td data-label="Item">
                                                                    <div className="row">
                                                                        <div className="col cart-item__image">
                                                                            <img src={`https://api.vivigon.com${item.product.product?.image_list![0].image}`} />
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="grid"> 
                                                                                <div className="cart-item__data">
                                                                                    <h3><a href={`/lieferer/${item.product.product?.seller.slug}/`}>{item.product.product?.seller.content_seller.title}</a> | <a href={`/luxusmarken/${item.product.product?.brand.slug}/`}>{item.product.product?.brand.content_brand.name}</a></h3>
                                                                                    <h2>{item.product.product?.content_product.name}</h2>
                                                                                    <div className="cart-item__max-quantity">{language.Deutsch.maximum}: {item.size.quantity}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="cart-item_quantity-block">
                                                                    <div className="center cart-item__quantity">
                                                                        <button className="cart-count-btn minus"  onClick={()=>{
                                                                            if(item.count-1 > 0)
                                                                                SwitchCount(item.product.product!.pk, item.count-1, item.size.pk)
                                                                        }}>
                                                                            <div className="grid">
                                                                                <img src={min} />
                                                                            </div>
                                                                        </button>
                                                                        <input type="number" readOnly={true} value={item.count} />
                                                                        <button className="cart-count-btn plus" onClick={()=>{
                                                                            if(item.count+1 <= item.size.quantity)
                                                                                SwitchCount(item.product.product!.pk, item.count+1, item.size.pk)
                                                                        }}>
                                                                            <div className="grid">
                                                                                <img src={plass} />
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td data-label="Price">
                                                                    <div>
                                                                        <div className="cart-item__price center">€{item.product.product?.finalPrice}</div>
                                                                        {
                                                                            item.product.product?.discount ? (
                                                                                <div className="cart-item__desc-price center">€{item.product.product?.price}</div>
                                                                            ):""
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td data-label="" className="center">
                                                                    <img src={trash} className="cart-item__trash" onClick={()=>{
                                                                        setLoadCart(true)
                                                                        auth.user.cart.DelInCart(auth.user.userToken, auth.user.userUid, {
                                                                            pk: item.product.product!.pk, 
                                                                            count: item.count+1, 
                                                                            size: item.size.pk
                                                                        }).then(function(response){
                                                                            setLoadCart(false)
                                                                        }).catch(function(error){
                                                                            setLoadCart(false)
                                                                        })
                                                                    }} />
                                                                </td>
                                                            </tr>  
                                                        )
                                                    })
                                                ):""
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cart-info">
                                <h3 className="title margin-title">{language.Deutsch.orderInfo}</h3> 

                                <div className="cart-info__lines">
                                    <p className="cart-info__line">
                                        {language.Deutsch.weight}: <span>{auth.user.cart.fWeight} gramm</span>
                                    </p>

                                    
                                    <p style={{display: "none"}} className="cart-info__line">
                                        {language.Deutsch.shipping}: <span>€</span>
                                    </p>
                                    

                                    <p className="cart-info__line">
                                        {language.Deutsch.subtotal}: <span>€{auth.user.cart.fCost}</span>
                                    </p> 
                                    <p className="cart-info__line">
                                        {language.Deutsch.discount}: <span className="red">€{auth.user.cart.fCost - auth.user.cart.fDescCount}</span>
                                    </p> 
                                </div>
                                <div className="cart-info__final-cost">
                                    {language.Deutsch.totalCost}:
                                    <span>€{auth.user.cart.fDescCount}</span>
                                </div>
                                {
                                    auth.user.cart.list.length ? (
                                        <p className="cart-info__to-order center">
                                            <Link to="/warenkorb/zur-kasse/">{language.Deutsch.checkout}</Link>
                                        </p>
                                    ):""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}