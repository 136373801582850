export const language = {
    "Deutsch": {
        "home": "STARTSEITE",
        "top": "handelsangebote",
        "clear": "Filter löschen",
        "sort": "Sortieren nach",
        "expensiveFirst": "Teure zuerst",
        "cheapFirst": "Billige zuerst",
        "bestsellersFirst": "Bestseller zuerst",
        "bestReview": "Beste Bewertung",
        "recommend": "Recommended",
        "search": "Suche"
    }
}