import { useState } from "react"

export const CategoryItemMob = (props: any) => { 
    const [open, setOpen] = useState(false)

    return (
        <li className={`catalog-mobile__lvl-${props.deep} ${props.item.children ? props.item.children.length ? " arrow-mob-item":"":""} ${props.item.children ? props.item.children.length && open == true ? " active":"":""}`}>
            <a href={`/katalog/${props.item.slug}/`} onClick={(event)=>{
                if(props.item.children && props.item.children.length){
                    if(!open){
                        event.preventDefault()
                        setOpen(true)
                    }
                }
            }}>{props.item.name}</a>
            {
                props.item.children ? (
                    props.item.children.length ? (
                        <ul>
                            {props.GetNode(props.item.children, props.deep+1)}
                        </ul>
                    ):""
                ):""
            }
        </li>
    )
}
