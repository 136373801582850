export const language = {
    "Deutsch": {
        "home": "STARTSEITE",
        "cart": "Korb",
        "product": "Ware",
        "count": "Anzahl",
        "price": "Preis",
        "maximum": "Maximum",
        "orderInfo": "Bestellubersucht",
        "weight": "Gewicht",
        "shipping": "Versandkosten",
        "subtotal": "Zwischensumme",
        "discount": "Versandrabatt",
        "totalCost": "Bestellsumme",
        "checkout": "Zur Kasse"
    }
}