import { useContext, useState } from 'react'
import {country} from '../../classes/country'
import { GlobalContext } from '../../context/GlobalContext'
import { language } from './language'
export const CountrySelector = (props: any) => {
    const auth = useContext(GlobalContext) 
    const [openList, setOpenList] = useState(false)
    return (
        <div className="popup-city">
            <div>
                <h2 className="title margin-title">{language.Deutsch.choose}</h2> 
                    <label>
                        {language.Deutsch.addressList}:             
                        <div className="select"> 
                            <div className="select__header" onClick={()=>{setOpenList(!openList)}}>
                                <span className="select__current" style={{backgroundImage: `url(${auth.user.country!.image})`}}>{auth.user.country!.name}</span>
                                <div className="select__icon"></div>
                            </div>
                            {
                                openList ? (
                                    <div className="select__body"> 
                                        {
                                            country.map((item, index)=>{
                                                return (
                                                    <div key={index} onClick={()=>{auth.user.country = item; setOpenList(false)}} className="select__item" style={{backgroundImage: `url(${item.image})`}}>{item.name}</div>
                                                )
                                            })
                                        }
                                    </div>
                                ):""
                            }
                        </div>
                    </label>
                    <button className="btn-black" onClick={()=>{
                        auth.user.ConfCountry()
                        window.location.reload();
                    }}>{language.Deutsch.select}</button>
                </div>
            </div>
    )
}