import { Link, useParams, useSearchParams } from "react-router-dom";
import { Product } from "../../component/Product"
import { ProductSlider } from "../../component/ProductSlider"
import { language } from "./language"
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { BreadItemType, CatalogProducts } from "../../classes";
import { Selector } from "../../component/Selector";
import { FilterInput } from "../../component/FilterInput";
import { Pagination } from "../../component/Pagination";
import { Loader } from "../../component/Loader";

export const Catalog = () => { 
    const auth = useContext(GlobalContext)    
    const { slug } = useParams();
    
    const [searchParams, setSearchParams] = useSearchParams();
    

    const [breadcrumbs, setBreadcrumbs] = useState<BreadItemType[]>()
    const [products, setProducts] = useState(new CatalogProducts())
    const [load, setLoad] = useState(true)

    const [formMinPrice, setFormMinPrice] = useState(0)
    const [formMaxPrice, setFormMaxPrice] = useState(9999999999)
    const [formBrand, setFormBrand] = useState("")
    const [formSort, setFormSort] = useState("expensiveFirst")

    const UpdateList = (page: number) => {
        products.page = page
        console.log(formMinPrice)
        setLoad(true)
        products.LoadData(slug ? slug : "", formMinPrice, formMaxPrice, formBrand, formSort, searchParams.get('search') ? `${searchParams.get('search')}`:'', auth.user.country.value).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{ 
        if(slug){
            const timer = setInterval(() => {
                if(auth.category.treeList.length >= 1){
                    clearInterval(timer)
                    auth.category.GetBreadcrumbs(slug).then(function(response){
                        setBreadcrumbs(response)
                    }).catch(function(error){
                        alert("error")
                    })
                }
            }, 1000);
        }
        UpdateList(products.page)
    },[])

    //costInput 
    


    if(load)
        return (
            <div className="catalog-loader">
                <Loader />
            </div>
        )

    return (
        <>
            <div className="breadcrumb">
                <div className="content">
                    <div className="breadcrumb-list">

                        <div className="breadcrumb-list__item">
                            <a href="/">
                                {language.Deutsch.home}
                            </a>
                        </div>
                        
                        {
                            breadcrumbs ? (
                                breadcrumbs.length ? (
                                    breadcrumbs.map((item, index) => {
                                        if(index == breadcrumbs.length-1)
                                            return(
                                                <div className="breadcrumb-list__item active">
                                                    {item.content_category[0].name}
                                                </div>
                                            )
                                        
                                        return(
                                            <div className="breadcrumb-list__item">
                                                <Link to={`/katalog/${item.slug}/`}>
                                                    {item.content_category[0].name}
                                                </Link>
                                            </div>
                                        )
                                    })
                                ):""
                            ):""
                        }
                    </div>
                </div>
            </div>

            <section className="products-list catalog">
                <div className="content big-padding__btn">
                    <h3 className="title margin-title">
                        {
                            breadcrumbs ? (
                                breadcrumbs.length ? (
                                    breadcrumbs[breadcrumbs.length-1].content_category[0].name
                                ):""
                            ):""
                        }
                    </h3> 
                    <div className="catalog__filter">
                        <div id="catalog__filter">
                            <input type="hidden" name="s" value="{{s}}" />
                            <div className="row"> 
                                <div className="col-md-3">  
                                    <label>
                                        {language.Deutsch.price}:         
                                        <FilterInput UpdateList={UpdateList} type={"number"} value={formMinPrice != 0 ? formMinPrice : ""} onChange={setFormMinPrice} placeholder={language.Deutsch.from} />  
                                    </label>       
                                </div>
                                <div className="col-md-3"> 
                                    <label>
                                        <br/>      
                                        <FilterInput UpdateList={UpdateList} type={"number"} value={formMaxPrice != 9999999999 && formMaxPrice != 0 ? formMaxPrice : ""} onChange={setFormMaxPrice} placeholder={language.Deutsch.to} />  
                                    </label>
                                </div> 
                                <div className="col-md-3"> 
                                    <label>
                                        {language.Deutsch.brand}:             
                                        <Selector 
                                            list={products.brand} 
                                            value={formBrand} Set={setFormBrand}
                                        />
                                    </label>
                                </div> 
                                <div className="col-md-3">
                                    <label>
                                        {language.Deutsch.sort}:            
                                        <Selector list={[
                                            {
                                                "title": language.Deutsch.expensiveFirst,
                                                "value": "expensiveFirst"
                                            },
                                            {
                                                "title": language.Deutsch.cheapFirst,
                                                "value": "cheapFirst"
                                            },
                                            {
                                                "title": language.Deutsch.bestsellersFirst,
                                                "value": "bestsellersFirst"
                                            },
                                            {
                                                "title": language.Deutsch.bestReview,
                                                "value": "bestReview"
                                            }
                                        ]} value={formSort} Set={setFormSort} />        
                                    </label>
                                </div>
                            </div>
                            

                            <p className="catalog__filter-btns">
                                <button 
                                    onClick={()=>{
                                        UpdateList(products.page)
                                    }}
                                >
                                    {language.Deutsch.search}
                                </button>
                                <span 
                                    style={{
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setFormMinPrice(0)
                                        setFormMaxPrice(9999999999)
                                        setFormBrand("")
                                        setFormSort("")
                                        UpdateList(products.page)
                                    }}
                                >{language.Deutsch.clear}</span>
                            </p>
                        </div>
                    </div> 
                    <div className="row">
                        {
                            products.list ? (
                                products.list.length ? (
                                    products.list.map((item, index)=>{
                                        return(
                                            <div key={index} className="col-md-6 col-lg-3">
                                                <div className="slider__item"> 
                                                    <Product item={item} />
                                                </div>
                                            </div>
                                        )
                                    })
                                ):""
                            ):""
                        }
                    </div>  

                    
                    <Pagination 
                        page={products.page}
                        pageCount={products.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={UpdateList}                         
                    />          
                </div>
            </section>
        </>
    )
    //<ProductSlider products={[1,2,3,4,5,6,7,2]} />
}