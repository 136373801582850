export const language = {
    "Deutsch": {
        "email": "email",
        "password": "Passwort",
        "confPassword": "Passwort vergessen?",
        "enter": "Einloggen",
        "login": "Einloggen",
        "register": "Ein Konto erstellen",
        "loginError": "Der Benutzername oder das Passwort ist falsch"
    }
}