import { ChangeEvent, useEffect, useRef, useState } from "react";

export const FilterInput = (props: any) => {
    const [active, setActive] = useState(false) 
    const [value, setValue] = useState<string|number>(props.value) 

    useEffect(() => {
        if(value)
            props.onChange(value)
    },[value])
 
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(
            props.type == "number" ? Number(event.target.value) : event.target.value
        ) 
        setActive(true)
    }

    return( 
        <input 
            type={props.type}
            value={value}
            onChange={changeHandler}
            placeholder={props.placeholder}
        />
    )
}