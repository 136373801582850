export const language = {
    "Deutsch": {
        "home": "STARTSEITE",
        "addToCart": "ZUM WARENKORB HINZUFÜGEN",
        "maximum": "Maximum",
        "payAndDeliv": "Bezahlung und Lieferung",
        "share": "Teilen",
        "moreProduct": "Ähnliche Produkte, die Ihnen gefallen könnten",
        "showMore": "ALLES ANZEIGEN",
        "descTitle": "BESCHREIBUNG",
        "reviewsTitle": "BEWERTUNGEN",
        "productStoryTitle": "DIENSTLEISTUNGEN durchsuchen",
        "desc": "BESCHREIBUNG",
        "unactive": "Produkt ist nicht verfügbar"
    }
}